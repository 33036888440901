import React, { useRef, useContext } from "react";
import { Accordion as RAccordion, AccordionContext } from "react-bootstrap";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import styled from "styled-components";
import { SGreyBlock, SGreyBlockTextPadding, SContainer } from "./GreyBlock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
`;

const Text = styled.div`
  margin-top: 20px;
`;

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );
  const isCurrentEventKey = activeEventKey === eventKey;
  return (
    <TitleContainer onClick={decoratedOnClick}>
      <Title dangerouslySetInnerHTML={{ __html: children }} />
      <FontAwesomeIcon icon={isCurrentEventKey ? faMinus : faPlus} />
    </TitleContainer>
  );
};

export const Accordion = (props) => {
  console.log(props);
  return (
    <RAccordion>
      <SGreyBlock>
        <SContainer
          className="accordion-container"
          style={{ padding: "20px", display: "flex", flexDirection: "column" }}
        >
          <ContextAwareToggle eventKey="0">{props.title}</ContextAwareToggle>
          <RAccordion.Collapse eventKey="0">
            <Text dangerouslySetInnerHTML={{ __html: props.text }} />
          </RAccordion.Collapse>
        </SContainer>
      </SGreyBlock>
    </RAccordion>
  );
};
