import React, { Component } from "react";
import { connect } from "react-redux";
import { Social } from "./Social";
import { EmailForm } from "./EmailForm";

export class ContactComp extends Component {
  render() {
    const { competitionClosed } = this.props;
    return (
      <div className="contact">
        <div>
          <div className="title">Contact Us</div>
          {!competitionClosed && (
            <div style={{ fontSize: 20 }}>
              <div className="text">
                <a href="mailto:lottery@cdfclottery.com.au">lottery@cdfclottery.com.au</a>
              </div>
              <br />
              <div style={{ fontWeight: "bold" }}>X Convenience</div>
              Oval Goodman Road,
              <br />
              Elizabeth SA 5112 Australia
            </div>
          )}
          {competitionClosed && (
            <div className="text">
              Send us an email at
              <br />
              <a href="mailto:lottery@cdfclottery.com.au">lottery@cdfclottery.com.au</a>
            </div>
          )}
          {/* <Social light /> */}
        </div>
        {/* <EmailForm {...this.props}/>  */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.service,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export const Contact = connect(mapStateToProps, mapDispatchToProps)(ContactComp);
