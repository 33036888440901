import React, { Component } from "react";
import Footer from "../components/Footer";
import PageHeader, { PageHeaderSpacer } from "../components/PageHeader";
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import GreyBlock from "../components/GreyBlock";
import ButtonWithBg from "../components/ButtonWithBg";
import { Helmet, BuyTicketsFixedBottom } from "../components";
import {
  competitionState,
  CS_COMPETITION_CLOSED,
  CS_EARLY_BIRD_DEADLINE,
} from "../store/service";
import styled from "styled-components";

const TopRow = styled.div`
  @media only screen and (max-width: 1000px) {
    margin-top: ${competitionState == CS_EARLY_BIRD_DEADLINE ? "55px" : "0px"};
  }
`;

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <TopRow className="privacy-policy-page">
        <Helmet
          title="Privacy Policy | Central District Football Club Lottery"
          link="/privacy-policy"
          description="The Central District Football Club is committed to protecting and maintaining the privacy, accuracy and security of all supporter information including the financial level of their support."
        />

        <PageHeader
          showBack={false}
          {...this.props}
          buttonClick={this.buttonClick}
        />
        <PageHeaderSpacer />
        <BuyTicketsFixedBottom
          competitionClosed={competitionState == CS_COMPETITION_CLOSED}
        />

        <div className="confetti-header"></div>

        <div className="impact-intro">
          <Container>
            <div className="subtitle lg">Privacy Policy</div>
            <div className="text terms">
              <p>
                The Central District Football Club is committed to providing
                quality services to you and this policy outlines our ongoing
                obligations to you in respect of how we manage your Personal
                Information.
                <br />
                <br />
                We have adopted the Australian Privacy Principles (APPs)
                contained in the Privacy Act 1988 (Cth) (the Privacy Act). The
                Australian Privacy Principles govern the way in which we
                collect, use, disclose, store, secure and dispose of your
                Personal Information.
                <br />
                <br />A copy of the Australian Privacy Principles may be
                obtained from the website of The Office of the Australian
                Information Commissioner at{" "}
                <a href="https://www.oaic.gov.au">www.oaic.gov.au</a>
                <br />
                <br />
                <strong>
                  What is Personal Information and why do we collect it?
                </strong>
                <br />
                <br />
                Personal Information is information or an opinion that
                identifies an individual. Examples of Personal Information we
                collect include: names, addresses, email addresses, phone and
                facsimile numbers.
                <br />
                <br />
                This Personal Information is obtained in many ways including
                interviews, correspondence, telephone, by email, your website,
                media and publications or other publicly available sources, and
                from third parties. We don’t guarantee website links or policy
                of authorised third parties.
                <br />
                <br />
                We collect your Personal Information for the primary purpose of
                providing our services to you, providing information to our
                clients and marketing. We may also use your Personal Information
                for secondary purposes closely related to the primary purpose,
                in circumstances where you would reasonably expect such use or
                disclosure. You may unsubscribe from our mailing/marketing lists
                at any time by contacting us in writing.
                <br />
                <br />
                When we collect Personal Information, we will where appropriate
                and where possible, explain to you why we are collecting the
                information and how we plan to use it.
                <br />
                <br />
                <strong>Sensitive Information</strong>
                <br />
                <br />
                Sensitive information is defined in the Privacy Act to include
                information or opinion about such things as an individual's
                racial or ethnic origin, political opinions, membership of a
                political association, religious or philosophical beliefs,
                membership of a trade union or other professional body, criminal
                record or health information.
                <br />
                <br />
                Sensitive information will be used by us only:
                <br />
                <br />
                  •                For the primary purpose for which it was
                obtained
                <br />
                  •                For a secondary purpose that is directly
                related to the primary purpose
                <br />
                  •                With your consent; or where required or
                authorised by law.
                <br />
                <br />
                <strong>Third Parties</strong>
                <br />
                <br />
                Where reasonable and practicable to do so, we will collect your
                Personal Information only from you. However, in some
                circumstances we may be provided with information by third
                parties. In such a case we will take reasonable steps to ensure
                that you are made aware of the information provided to us by the
                third party.
                <br />
                <br />
                <strong>Disclosure of Personal Information</strong>
                <br />
                <br />
                Your Personal Information may be disclosed in a number of
                circumstances including the following:
                <br />
                <br />
                  •                Third parties where you consent to the use or
                disclosure; and
                <br />  •                Where required or authorised by law.
                <br />
                <br />
                <strong>Security of Personal Information</strong>
                <br />
                <br />
                Your Personal Information is stored in a manner that reasonably
                protects it from misuse and loss and from unauthorized access,
                modification or disclosure. When your Personal Information is no
                longer needed for the purpose for which it was obtained, we will
                take reasonable steps to destroy or permanently de-identify your
                Personal Information. However, most of the Personal Information
                is or will be stored in client files which will be kept by us
                for a minimum of 7 years.
                <br />
                <br />
                <strong>Access to your Personal Information</strong>
                <br />
                <br />
                You may access the Personal Information we hold about you and to
                update and/or correct it, subject to certain exceptions. If you
                wish to access your Personal Information, please contact us in
                writing.
                <br />
                The Central District Football Club will not charge any fee for
                your access request, however in order to protect your Personal
                Information we may require identification from you before
                releasing the requested information.
                <br />
                <br />
                <strong>
                  Maintaining the Quality of your Personal Information
                </strong>
                <br />
                <br />
                It is an important to us that your Personal Information is up to
                date. We will take reasonable steps to make sure that your
                Personal Information is accurate, complete and up-to-date. If
                you find that the information we have is not up to date or is
                inaccurate, please advise us as soon as practicable so we can
                update our records and ensure we can continue to provide quality
                services to you.
                <br />
                <br />
                <strong>Policy Updates</strong>
                <br />
                <br />
                This Policy may change from time to time and is available on our
                website.
                <br />
                <br />
                <strong>Privacy Policy Complaints and Enquiries</strong>
                <br />
                <br />
                If you have any queries or complaints about our Privacy Policy
                please contact us at:
                <br />
                <br />
                Central District Football Club PO Box 10, Elizabeth SA 5112
                <br />
                <br />
                <a href="mailto:lottery@cdfclottery.com.au">
                  lottery@cdfclottery.com.au
                </a>
                <br />
                <br />
                <a href="tel:0882552555">08 8255 2555</a>
                <br />
                <br />
                Greg Edwards – Chief Executive Officer
              </p>
            </div>
          </Container>
        </div>

        <Footer />
      </TopRow>
    );
  }
}
