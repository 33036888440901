import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { loadingBarMiddleware } from "react-redux-loading-bar";
import "babel-polyfill";
import rootReducer from "./store/reducers";

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Welcome from "./containers/WelcomeContainer";
import BuyTickets from "./containers/BuyTicketsContainer";
import YourImpact from "./containers/YourImpactContainer";
import FAQs from "./containers/FAQsContainer";
import TermsAndConditions from "./containers/TermsAndConditionsContainer";
import PrivacyPolicy from "./containers/PrivacyPolicyContainer";
import PastWinners from "./containers/PastWinnersContainer";
//import MailchimpConfirmation from './containers/MailchimpConfirmationContainer';
import OurPrizes from "./containers/OurPrizesContainer";

const loggerMiddleware = createLogger();

const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware, loggerMiddleware, loadingBarMiddleware())
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div id="route-container">
        <Route exact path="/" component={Welcome} />
        <Route exact path="/buy-tickets" component={BuyTickets} />
        <Route exact path="/your-impact" component={YourImpact} />
        <Route exact path="/frequently-asked-questions" component={FAQs} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/past-winners" component={PastWinners} />
        {/*<Route exact path="/vip-confirmation" component={MailchimpConfirmation} />*/}
        {/* <Route exact path="/our-prizes" component={OurPrizes} /> */}
      </div>
    </Router>
  </Provider>,
  document.getElementById("root")
);
