import React, { Component } from 'react';
import Footer from '../components/Footer';
import PageHeader, { PageHeaderSpacer } from '../components/PageHeader';
import { Container, Row, Col, Button, Carousel, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import GreyBlock from '../components/GreyBlock';
import ButtonWithBg from '../components/ButtonWithBg';
import { Contact } from '../components/Contact';
import { Helmet, Accordion, BuyTicketsFixedBottom } from '../components';
import {
  competitionState,
  CS_COMPETITION_CLOSED,
  CS_EARLY_BIRD_DEADLINE,
  CS_FIRST_PRIZE_DRAWN,
} from '../store/service';
import styled from 'styled-components';

const TopRow = styled.div`
  @media only screen and (max-width: 1000px) {
    margin-top: ${competitionState == CS_FIRST_PRIZE_DRAWN ? '15px' : '0px'};
  }
`;

export default class FAQs extends Component {
  render() {
    return (
      <TopRow className="your-impact-page">
        <Helmet
          title="Frequently Asked Questions | Central District Footbal Club Lottery"
          link="/frequently-asked-questions"
          description="With only 3,750 tickets available, for just $50 you have a 1 in 3,750 chance of winning a choice of $45,000 cash OR a Peter Page Hyundai Santa Fe Active petrol FWD valued at $52,990!"
        />

        <PageHeader showBack={false} {...this.props} />
        <PageHeaderSpacer />
        <BuyTicketsFixedBottom competitionClosed={competitionState == CS_FIRST_PRIZE_DRAWN} />

        <div className="confetti-header"></div>

        <div className="impact-intro">
          <Container>
            <div className="subtitle lg">Frequently Asked Questions</div>
          </Container>
        </div>

        <Accordion
          title="Is this a registered lottery program?"
          text="Yes, this lottery is a registered lottery program. The Lottery Permit, issued by the Government of South Australia Consumer and Business Services, is #M14290 and the permit holder is the Central District Football Club."
        />

        <Accordion
          title="How can I contact the CDFC Lottery?"
          text="You can contact the Central District Football Club on <a href='mailto:lottery@cdfclottery.com.au'>lottery@cdfclottery.com.au</a>
"
        />

        <Accordion
          title="What prizes can be won and when are the draws?"
          text="
<p>
<strong>Early Bird Prize</strong><br/>
The Early Bird Prize winner will win $2,000 in Cash. Only tickets purchased before 11:59pm on 9th November, 2022 are eligible for the Early Bird Prize. The winner of the Early Bird Prize will remain eligible to win the First Prize.
</p>

<p>
<strong>First Prize</strong><br/>
The First Prize winner may choose between $45,000 cash OR a Peter Page Hyundai Santa Fe Active petrol FWD valued at $52,990.
</p>
<strong>Draws</strong><br/>
The Early Bird Prize will be drawn from 10.00am on 10th November 2022.
The First Prize will be drawn from 10.00am on 31st July 2023. All draws will occur at the offices of the Central District Football Club Inc, Goodman Road, Elizabeth, SA 5112.
</p>"
        />

        <Accordion
          title="How much is the total prize pool worth?"
          text="Total recommended retail value of all prizes is approximately $55,000."
        />

        <Accordion title="How many tickets will be sold?" text="A maximum of 3,750 tickets will be sold." />

        <Accordion title="What is the ticket price?" text="Tickets are $50 each." />

        <Accordion
          title="Where can I purchase tickets?"
          text="Tickets can be purchased online by clicking the buy tickets button on this website."
        />

        <Accordion
          title="How will I receive my ticket?"
          text="Your tickets will be emailed to your nominated email address."
        />

        <Accordion
          title="How will ticket numbers be displayed if I buy multiple tickets?"
          text="<p>When you purchase multiple tickets, your tickets numbers will be listed on one ticket.</p>
                    <p>For example:<br/>
                    TICKET NUMBER/S: CDFC_001_0350 CDFC_001_0351 CDFC_001_0352</p>"
        />

        <Accordion
          title="If I purchase a ticket, will my account be automatically debited for future lotteries?"
          text="No"
        />

        <Accordion
          title="Can my ticket win multiple prizes?"
          text="Yes. The winner of the Early Bird Prize will remain eligible to win the First Prize."
        />

        <Accordion title="How old do I need to be to purchase a ticket?" text="You must be 18 years old, or older." />

        <Accordion
          title="Where will the prize draws take place?"
          text="All draws will occur at the offices of the Central District Football Club Inc,  Goodman Road, Elizabeth, SA 5112."
        />

        <Accordion
          title="How will the prize draws be supervised?"
          text="All draws will occur:
(i) under the supervision of the Central District Football Club and Novita Services;
(ii) in the presence of a scrutineer who is not associated with the Central District Football Club and Novita Services or otherwise involved in the conduct of the lottery; and
(iii) in the presence of any ticket holders who wish to be present.
"
        />

        <Accordion
          title="How will I know if I have won a prize?"
          text="All winners will be contacted via a telephone call or email from the Central District Football Club and Novita Services.
The prize winner’s name will be published online at cdfc.com.au and novita.org.au no later than 24 hours after the prize is drawn
"
        />

        <Accordion
          title="How will I receive my prize?"
          text="It is the responsibility of the prize winner to claim their prize.
Prizes will be awarded to the first person named on the official ticket. Prize- winners may be asked to provide 100 points of identification, as a condition of prize collection occurring.
Each winner will have six-months from the final draw date to claim their prize.
"
        />

        <Accordion
          title="Where do the proceeds from the CDFC Lottery go?"
          text="<strong>Central District FC
</strong></br>Central District Football Club is a sporting association that competes in the SANFL competitions. It provides a safe and welcoming environment for Men, Women, Boys and Girls to compete and learn football and life skills. It is an integral part of the Northern suburbs community. CDFC seeks to create a sense of inclusion and belonging with our community, providing joy, inspiration and opportunity.The proceeds of this lottery will enable CDFC to continue to support established programmes and meet new needs as they arise.
</br> 
<strong>Novita</strong></br> 
Novita provides rehabilitation, therapy, early intervention, community inclusion, assistive technology and accomodation services to people living with disability, as well as support for their families and carers. By purchasing a ticket in this lottery, you’re supporting the purchase of vital equipment to help people living with disability achieve their goals – thank you.

                   "
        />

        <div className="impact-footer" style={{ textAlign: 'center' }}>
          <Container>{!this.props.competitionClosed && <ButtonWithBg href="/buy-tickets" />}</Container>
        </div>

        <div className="contact-and-partners">
          <a name="signup" id="signup" />
          <Container>
            <div className="divider" />
            <Contact />
          </Container>
        </div>
        <Footer />
      </TopRow>
    );
  }
}
