import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { BuyTicketsRow } from "./BuyTicketsRow";
import { useScrollPoint } from "../hooks";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextContainer = styled.div`
  background-color: white;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
  padding-top: 80px;
  @media only screen and (max-width: 800px) {
    padding-bottom: 30px;
    padding-top: 30px;
  }
`;

const Desc = styled.div`
  font-size: 60px;
  line-height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  max-width: 1920px;
  z-index: 4;
  color: black;
  @media only screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 25px;
  }
`;

export const YouWinYouChoose = (props) => {
  const below = useScrollPoint(800);
  return (
    <Container>
      <TextContainer>
        <Desc>
          <div style={{ color: "#0033a1", marginRight: "1ch" }}>
            FIRST PRIZE
          </div>
          YOU WIN YOU CHOOSE
        </Desc>
      </TextContainer>
    </Container>
  );
};
