import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const Img = styled.img`
  position: relative;
  width: 100%;
  height: 1000px;
  max-width: 100%;
  max-height: 50vh;
  object-position: center 30%;
  object-fit: cover;
  z-index: 3;
`;

export const EarlyBirdPrize = (props) => {
  return <Img src="/images/gold-bullion-1.jpg" {...props} />;
};

export const CashPrize = (props) => {
  return <Img src="/images/cash.jpg" {...props} />;
};
