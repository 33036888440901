import React, { Component } from "react";
import Footer from "../components/Footer";
import PageHeader, { PageHeaderSpacer } from "../components/PageHeader";
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import GreyBlock from "../components/GreyBlock";
import ButtonWithBg from "../components/ButtonWithBg";
import { Contact } from "../components/Contact";
import {
  Helmet,
  BuyTicketsFixedBottom,
  EarlyBirdPrize,
  CashPrize,
  FirstPrizeCarousel,
  OurPrizeText,
} from "../components";
import { H1, H2 } from "../styles";
import styled from "styled-components";
import {
  competitionState,
  CS_EARLY_BIRD_DEADLINE,
  CS_FIRST_PRIZE_DRAWN,
} from "../store/service";
import Confetti from "react-confetti";

const TopRow = styled.div`
  @media only screen and (max-width: 1000px) {
    margin-top: ${competitionState == CS_FIRST_PRIZE_DRAWN ? "15px" : "0px"};
  }
`;

export const OurPrizes = (props) => {
  return (
    <TopRow className="our-prizes-page">
      {/* <Helmet
        title="Our Prizes | Women’s & Children’s Hospital Foundation Lottery"
        link="/our-prizes"
        description=" For just $50 you could win First Prize; a choice of $150,000 in CASH, OR a Toyota Prado Kakadu PLUS a Windsor Genesis Caravan (RRP $160,255)! Early Bird Prize winner will win Gold Bullion to the value of $20,000!"
      />

      <PageHeader showBack={false} {...props} />
      <PageHeaderSpacer />
      <BuyTicketsFixedBottom competitionClosed={props.competitionClosed} />

      <div className="confetti-header">
        <img src="/images/confetti.jpg" />
      </div>

      <Container>
        <H1>
          {" "}
          WIN $150,000 in CASH, OR a Toyota Prado Kakadu PLUS a Windsor Genesis
          Caravan!
        </H1>
        <p>
          Purchase your Women’s & Children’s Hospital Foundation Lottery tickets
          before midnight 13 December 2022 to enter our First Prize Draw for
          your chance to WIN a choice of $150,000 in CASH, OR a Toyota Prado
          Kakadu PLUS a Windsor Genesis Caravan (RRP $160,255)!
        </p>
      </Container>

      <FirstPrizeCarousel />

      <Container>
        <H1>ADVENTURE VEHICLE COMBO & CASH</H1>
        <p>
          <b>
            Win the Ultimate Adventure Combo; a Toyota Prado Kakadu PLUS a
            Windsor Genesis 196MD Caravan.
          </b>
        </p>
        <p>
          The legendary off-road Toyota Prado Kakadu takes on any challenge with
          power and safety. This 2.8L T Diesel 7-seater has superior pull
          ability, making it the ideal set-up for towing your Windsor Caravan on
          every weekend journey, while not compromising on style, comfort and
          practicality for zipping around town throughout the week.
        </p>
        <p>
          The spacious Windsor Genesis 196MD Caravan is the perfect
          home-away-from-home. Along with plenty of clever storage, this caravan
          features a well-equipped kitchen, full ensuite bathroom with a fully
          enclosed shower and frosted door, two bunks, a comfortable queen-size
          island bed with lift storage access, air conditioning, laundry and
          much more.
        </p>
      </Container>

      <CashPrize />

      <Container>
        <H1>CASH</H1>
        <p>
          Not in the market for a new vehicle? Win First Prize and choose to
          take home $150,000 in cash, transferred directly into your bank
          account for immediate use. With $150,000 to spend absolutely any way
          you like, what would you buy first?
        </p>
        <p>
          Want more? Buy your ticket before 11am 15 September 2022 and you could
          win an extra $5,000 in our exclusive VIP Draw
          <br />
          <br />
        </p>
      </Container>

      <EarlyBirdPrize style={{ marginBottom: "1rem" }} />

      <Container style={{ marginBottom: "5rem" }}>
        <H1>EARLY BIRD PRIZE: WIN $20,000 of Gold Bullion</H1>
        <p>
          All tickets purchased before midnight 18 October 2022 will also enter
          our Early Bird Draw to win Gold Bullion to the value of $20,000!
        </p>
        <p>
          Your Gold Bullion will be valued on redemption at City Gold Bullion.
          Trade it back right away for cash or hold on to it a little longer to
          create a luxury investment of precious metal.
        </p>
      </Container>

      {!props.competitionClosed && (
        <Container style={{ maxWidth: "100%", padding: "0px", margin: "0px" }}>
          <OurPrizeText />
        </Container>
      )}

      <div className="contact-and-partners">
        <a name="signup" id="signup" />
        <Container>
          <div className="divider" />
          <Contact />
        </Container>
      </div>

      <Footer /> */}
    </TopRow>
  );
};
