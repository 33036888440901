import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import FallingCash from "./FallingCash";

const debug = false;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 1000px;
  max-width: 100%;
  max-height: 65vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 500px) {
    max-height: 37vh;
  }
`;

const CenterContainer = styled.div`
  background-color: ${(debug && "green") || "transparent"};
  position: relative;
  width: 1px;
  height: 90%;
  @media only screen and (max-width: 500px) {
    max-height: 48%;
  }
`;

const BackgroundImg = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-position: center bottom;
  object-fit: cover;
  @media only screen and (max-width: 500px) {
  }
`;

const CarContainer = styled.div`
  background-color: ${(debug && "red") || "transparent"};
  position: absolute;
  top: -7%;
  right: -66vh;
  overflow: visible;
  width: 130vh;
  height: 66vh;

  @media only screen and (max-width: 500px) {
    top: -39%;
    right: -20vh;
    width: 40vh;
    height: 40vh;
  }
`;

const CarImg = styled.img`
  height: 100%;
  width: 100%;
  object-position: center center;
  object-fit: contain;
`;

export const FirstPrize = (props) => {
  let trailerRef = useRef(null);
  let carRef = useRef(null);

  return (
    <Container className="first-prize-component">
      <BackgroundImg src="/images/asherMilgateFC.jpg" />
      <FallingCash />
      <CenterContainer className="inner-container">
        <CarContainer ref={(el) => (carRef = el)}>
          <CarImg src="/images/hyundaiSantaFeDash.png" />
        </CarContainer>
      </CenterContainer>
    </Container>
  );
};
