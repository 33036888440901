import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { BuyTicketsRow } from "./BuyTicketsRow";
import { useScrollPoint } from "../hooks";
import {
  competitionState,
  CS_COMPETITION_CLOSED,
  CS_EARLY_BIRD_CLOSED,
} from "../store/service";
import Confetti from "react-confetti";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextContainer = styled.div`
  background-color: #50c4f1;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;

  @media only screen and (max-width: 800px) {
    padding: 28px;
  }
`;

const Title1 = styled.div`
  font-size: 30px;
  line-height: 30x;
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  font-weight: normal;
  max-width: 1140px;
  z-index: 4;

  @media only screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

const Title2 = styled.div`
  font-size: 55px;
  line-height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  max-width: 1140px;

  @media only screen and (max-width: 800px) {
    font-size: 37px;
    line-height: 37px;
  }
`;

const Desc = styled.div`
  font-size: 37px;
  line-height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  max-width: 1140px;
  z-index: 4;

  @media only screen and (max-width: 800px) {
    font-size: 25px;
    line-height: 25px;
  }
`;

//var height = document.getElementsByClassName("earlyBird");
//console.log(height);

export const OurPrizeText = (props) => {
  const below = useScrollPoint(800);
  return (
    <Container class="earlyBird">
      {competitionState == CS_EARLY_BIRD_CLOSED ||
      competitionState == CS_COMPETITION_CLOSED ? null : (
        <BuyTicketsRow {...props} />
      )}
    </Container>
  );
};
