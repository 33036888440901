import React, { Component } from "react";
import Footer from "../components/Footer";
import PageHeader, { PageHeaderSpacer } from "../components/PageHeader";
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import GreyBlock from "../components/GreyBlock";
import ButtonWithBg from "../components/ButtonWithBg";
import { Contact } from "../components/Contact";
import { Helmet, BuyTicketsFixedBottom, OurPrizeText } from "../components";
import {
  competitionState,
  CS_COMPETITION_CLOSED,
  CS_EARLY_BIRD_DEADLINE,
  CS_FIRST_PRIZE_DRAWN,
  CS_VIP_OPEN,
} from "../store/service";
import styled from "styled-components";

const TopRow = styled.div`
  @media only screen and (max-width: 1000px) {
    margin-top: ${competitionState == CS_FIRST_PRIZE_DRAWN ? "15px" : "0px"};
  }
`;

export default class YourImpact extends Component {
  render() {
    return (
      <TopRow className="your-impact-page">
        <Helmet
          title="Your Impact | Central District Football Club Lottery"
          link="/your-impact"
          description="When you purchase a ticket in the  Central District Football Club Lottery, you’re supporting the purchase of vital equipment to help people living with disability achieve their goals – thank you."
        />

        <PageHeader showBack={false} {...this.props} />
        <PageHeaderSpacer />
        <BuyTicketsFixedBottom
          competitionClosed={competitionState == CS_FIRST_PRIZE_DRAWN}
        />

        <div className="impact-hero"></div>

        <div className="impact-intro" style={{ marginBottom: 50 }}>
          <Container>
            <div className="subtitle lg">Central District Football Club</div>
            <div className="text">
              <p>
                Central District Football Club is a sporting association that
                competes in the SANFL competitions. It provides a safe and
                welcoming environment for Men, Women, Boys and Girls to compete
                and learn football and life skills. It is an integral part of
                the Northern suburbs community.
                <br />
                <br />
                CDFC seeks to create a sense of inclusion and belonging with our
                community, providing joy, inspiration and opportunity. The
                proceeds of this lottery will enable CDFC to continue to support
                established programmes and meet new needs as they arise.
              </p>
            </div>
          </Container>
        </div>
        <Container
          className="impact-3 noPadding"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            className="impact-img"
            src="/images/impactCDFC.jpg"
            style={{
              maxHeight: "100%",
              marginBottom: "30px",
              textAlign: "center",
              maxWidth: "80%",
            }}
          />
        </Container>
        <div
          style={{
            backgroundColor: "#431641",
            color: "white",
            paddingTop: "40px",
            paddingBottom: "40px",
          }}
        >
          <Container>
            <div className="subtitle lg" style={{ color: "white" }}>
              Novita
            </div>
            <div className="text">
              <p>
                Brightening lives. Supporting brilliant care.
                <br />
                <br />
                Novita provides rehabilitation, therapy, early intervention,
                community inclusion, assistive technology and accomodation
                services to people living with disability, as well as support
                for their families and carers. By purchasing a ticket in this
                lottery, you’re supporting the purchase of vital equipment to
                help people living with disability achieve their goals – thank
                you
              </p>
            </div>
          </Container>
          <Container
            className="impact-3 noPadding"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              className="impact-img"
              src="/images/Impact.jpg"
              style={{
                maxHeight: "100%",
                textAlign: "center",
                maxWidth: "80%",
              }}
            />
          </Container>
        </div>
        <div className="contact-and-partners">
          <a name="signup" id="signup" />
          <Container>
            <div className="divider" />
            <Contact />
          </Container>
        </div>
        <Footer />
      </TopRow>
    );
  }
}
