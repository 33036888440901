const StringHelpers = {
    titleCase: (str) => {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    },
    pad: (num, size) => {
        var s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }
}

export default StringHelpers