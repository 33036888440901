import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import { Social } from "./Social";

const Footer = (props) => {
  return (
    <div>
      <div className="footer">
        <Container>
          <div className="left">
            <div className="logo">
              <a href="https://cdfclottery.com.au/" target="_blank">
                <img src="/images/CDFCLogo.png" />
              </a>
              <div className="license">Lottery Licence #M14290</div>
            </div>
          </div>

          <div className="right">
            <div className="links">
              <a href="/past-winners">Past-Winners</a>
              <a href="/your-impact">Your Impact</a>
              <a href="/frequently-asked-questions">FAQs</a>
            </div>

            <div className="links">
              <a href="/terms-and-conditions">Terms and Conditions</a>
              <a href="/privacy-policy">Privacy Policy</a>
            </div>
          </div>
        </Container>
      </div>

      <Row style={{ background: "#0033a1" }}>
        <div className="footer-bg"></div>
      </Row>
    </div>
  );
};

export default Footer;
