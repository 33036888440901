// https://stackoverflow.com/questions/59736184/is-there-a-way-to-override-the-browser-s-default-scroll-to-hash-behavior-on-page/59742690#59742690
import React, { Component } from 'react'

export const scrollToWithOffset = (element, offset, smooth) => {
    let offsetTop = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo(0, offsetTop);
/*
    let maxScrollTop = document.body.scrollHeight - document.body.clientHeight;
    let options = {
      top: Math.min(offsetTop - offset, maxScrollTop),
      left: 0,
    };
    if (smooth === true) {
      options.behavior = 'smooth';
    }
    window.scroll(options);*/
  };
  
  
  export class ScrollToHash extends Component {
      /*
    constructor(props) {
      super(props);
      this.handleScroll = this.handleScroll.bind(this);
    }
    handleScroll() {
      setTimeout(() => {
        if (this.state.element) {
          this.state.element.id = this.state.element.id.replace('-sth', '');
          setTimeout(() => {
            if (this.state.element) {
              scrollToWithOffset(this.state.element, 0);
            }
          }, 1000);
        }
      }, 0);
    }
    */
    componentDidMount() {
      if (window.location.hash !== '') {
        let element = document.getElementById(window.location.hash.replace('#', ''));
        if (element) {
            setTimeout(() => {
                scrollToWithOffset(element, 0);
            }, 100);
            /*
          element.id += '-sth';
          this.setState({
            element: element
          });
          if (document.readyState === 'loading') {
            window.addEventListener('DOMContentLoaded', this.handleScroll);
          } else {
            this.handleScroll();
          }*/
        }
      }
    }
    /*
    componentWillUnmount() {
      window.removeEventListener('DOMContentLoaded', this.handleScroll);
    }*/
    render() {
      return null;
    }
  }