import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { BuyTicketsRow } from "./BuyTicketsRow";
import { useScrollPoint } from "../hooks";
import {
  competitionState,
  CS_COMPETITION_CLOSED,
  CS_EARLY_BIRD_CLOSED,
} from "../store/service";
import ButtonWithBg from "./ButtonWithBg";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextContainer = styled.div`
  background-color: #db0032;
  color: white;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;

const Title1 = styled.div`
  font-size: 30px;
  line-height: 30x;
  display: flex;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  font-weight: normal;
  max-width: 1140px;
  z-index: 4;

  @media only screen and (max-width: 800px) {
    font-size: 20px;
    line-height: 20px;
  }
`;

const Title2 = styled.div`
  font-size: 55px;
  line-height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  max-width: 1140px;

  @media only screen and (max-width: 800px) {
    font-size: 37px;
    line-height: 37px;
  }
`;

const Desc = styled.div`
  font-size: 37px;
  line-height: 37px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  max-width: 1140px;
  z-index: 4;

  @media only screen and (max-width: 800px) {
    font-size: 25px;
    line-height: 25px;
  }
`;

//var height = document.getElementsByClassName("earlyBird");
//console.log(height);
const ContainerB = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 0px;
  overflow: visible;
  postiion: relative;
`;

const AbsContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: ${(props) => props.top || "-40px"};
`;
export const BuyTickets = (props) => {
  return (
    <ContainerB className={props.className}>
      <AbsContainer top={props.top}>
        <ButtonWithBg href="buy-tickets" className="blue" />
      </AbsContainer>
    </ContainerB>
  );
};
export const EarlyBirdPrizeText = (props) => {
  const below = useScrollPoint(800);
  return (
    <Container class="earlyBird">
      {competitionState == CS_EARLY_BIRD_CLOSED ||
      competitionState == CS_COMPETITION_CLOSED ? null : (
        <BuyTickets {...props} />
      )}
      <TextContainer
        style={{
          paddingTop:
            competitionState == CS_EARLY_BIRD_CLOSED ||
            competitionState == CS_COMPETITION_CLOSED
              ? 20
              : 40,
        }}
      >
        <Title2>EARLY BIRD PRIZE</Title2>
        <Desc>$2000 in cash</Desc>
        <Title1>
          Buy your tickets before 11:59pm on 9th of November 2022 to win.
        </Title1>
      </TextContainer>
    </Container>
  );
};
