import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useScrollPoint } from "../hooks";
import {
  competitionState,
  CS_COMPETITION_CLOSED,
  CS_EARLY_BIRD_CLOSED,
} from "../store/service";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextContainer = styled.div`
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  flex-wrap: wrap;
  @media only screen and (max-width: 800px) {
    padding: 28px;
  }
`;

const Desc = styled.div`
  font-size: 26px;
  font-family: Futura-pt;
  line-height: 35px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  z-index: 4;
  max-width: 510px;
  margin-top: 2ch;
  margin-bottom: 2ch;
  @media only screen and (max-width: 800px) {
    font-size: 25px;
    line-height: 25px;
  }
`;

const RButton = styled(Button)`
  background: url(/images/blueButtonVector.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  height: 70px;
  width: 356px;
  max-width: 356px;
  margin-left: 100px 

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 24px;
  font-weight: 700;
  color: white !important;

  border: none;
  transition: transform 0.1s ease-out;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: #white !important;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

const ButtonWithRead = (props) => {
  return (
    <Container className={`button-with-bg-container-temp ${props.className}`}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <RButton href={props.href} onClick={props.onClick}>
          READ MORE
        </RButton>
      </div>
    </Container>
  );
};

export const CDFCBanner = (props) => {
  return (
    <Container>
      <TextContainer>
        <img
          style={{
            maxWidth: "670px",
            height: "100%",
          }}
          src="/images/football.jpg"
        ></img>
        <div
          style={{
            textAlign: "center",
            display: "block",
            maxWidth: "1140px",
            marginBottom: "10px",
          }}
        >
          <img
            style={{
              maxWidth: "270px",
            }}
            src="/images/bulldogsLogo.jpg"
          ></img>
          <Desc>
            Central District Football Club is a sporting association that
            competes in the SANFL competitions. It provides a safe and welcoming
            environment...
          </Desc>
          <ButtonWithRead href="your-impact" />
        </div>
      </TextContainer>
    </Container>
  );
};
