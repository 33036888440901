import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-bootstrap";

const SCarousel = styled(Carousel)`
  height: 600px;

  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1;
    height: 80%;
  }

  .visually-hidden {
    display: none;
  }

  @media only screen and (max-width: 500px) {
    height: 450px;
  }
`;

const Img = styled.img`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #fff;
  width: 100%;
  object-position: ${(props) => props.position || "center center"};
  object-fit: ${(props) => props.fit || "cover"};
  height: 600px;

  @media only screen and (max-width: 500px) {
    height: 450px;
  }
`;
export const FirstPrizeCarousel = (props) => {
  return (
    <SCarousel controls={true} indicators={false} interval={4000} pause={false}>
      <Carousel.Item>
        <Img src="/images/toyota-prado-1.jpg" position="center 40%" />
      </Carousel.Item>

      <Carousel.Item>
        <Img src="/images/toyota-prado-3.jpg" position="center 46%" />
      </Carousel.Item>

      <Carousel.Item>
        <Img src="/images/toyota-prado-4.jpg" />
      </Carousel.Item>

      <Carousel.Item>
        <Img src="/images/windsor1.jpg" />
      </Carousel.Item>

      <Carousel.Item>
        <Img src="/images/windsor2.jpg" />
      </Carousel.Item>
    </SCarousel>
  );
};
