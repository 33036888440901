import React, { useState, useEffect } from 'react'

export const useScrollPoint = (yPoint) => {
    const [below, setBelow] = useState(false)
  
    const handleScroll = () => {
      const y = window.scrollY
        if (y >= yPoint) {
          setBelow(true)
        } else {
          setBelow(false)
        }
    }
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll)
    }, [])

    return below
  }