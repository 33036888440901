import React, { Component } from 'react';
import { Container, Row, Col, Button, Carousel, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment, { min } from 'moment';

import '../styles/global.css';
import { toInteger } from 'lodash';

export default class CountdownCompact extends Component {
  state = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    daysPerc: 0,
    hoursPerc: 0,
    minutesPerc: 0,
    secondsPerc: 0,
  };

  componentDidMount() {
    let self = this;

    this.timer = setInterval(() => {
      let today = moment();
      let endDate = moment(this.props.countdown);
      let diffDate = today.diff(endDate); //, "seconds")
      let diffDuration = moment.duration(Math.abs(diffDate));

      let days = toInteger(diffDuration / 86400000);
      let hours = diffDuration.hours();
      let minutes = diffDuration.minutes();
      let seconds = diffDuration.seconds();

      let daysPerc = (days / 90) * 100; //90 = total days from launch to closing
      let hoursPerc = (hours / 60) * 100;
      let minutesPerc = 100 - (new Date().getSeconds() / 60) * 100; //(minutes / 60) * 100
      let secondsPerc = (seconds / 60) * 100;

      if (new Date() > endDate.toDate()) {
        days = hours = minutes = daysPerc = hoursPerc = minutesPerc = 0;
      }

      self.setState({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds,
        daysPerc: daysPerc,
        hoursPerc: hoursPerc,
        minutesPerc: minutesPerc,
        secondsPerc,
      });
    }, 100);
  }

  render() {
    return (
      <div className={'countdown ' + this.props.className} style={this.props.style}>
        <CircularProgressbarWithChildren
          value={this.state.daysPerc}
          styles={buildStyles({
            // Colors
            pathColor: 'white',
            trailColor: 'rgba(255,255,255,0.15)',
          })}
        >
          <div className="counterText">{this.state.days}</div>
        </CircularProgressbarWithChildren>
        <div className="counterLabel">DAYS</div>

        <CircularProgressbarWithChildren
          value={this.state.hoursPerc}
          styles={buildStyles({
            // Colors
            pathColor: 'white',
            trailColor: 'rgba(255,255,255,0.15)',
          })}
        >
          <div className="counterText">{this.state.hours}</div>
        </CircularProgressbarWithChildren>
        <div className="counterLabel">HOURS</div>

        <CircularProgressbarWithChildren
          value={this.state.minutesPerc}
          styles={buildStyles({
            // Colors
            pathColor: 'white',
            trailColor: 'rgba(255,255,255,0.15)',
          })}
        >
          <div className="counterText">{this.state.minutes}</div>
        </CircularProgressbarWithChildren>
        <div className="counterLabel">MINS</div>

        <CircularProgressbarWithChildren
          value={this.state.secondsPerc}
          styles={buildStyles({
            // Colors
            pathColor: 'white',
            trailColor: 'rgba(255,255,255,0.15)',
          })}
        >
          <div className="counterText">{this.state.seconds}</div>
        </CircularProgressbarWithChildren>
        <div className="counterLabel">SEC</div>
      </div>
    );
  }
}
