
import React, { Component } from 'react'
import { Container, Row, Col, Button, Carousel, Form, DropdownButton, Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

export const SGreyBlock = styled.div`
    margin-top: 50px;
`

const SButton = styled(Button)`
    border-radius: 0px;
    background-color: #9D6BAC !important;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    padding:10px;
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {
        text-decoration: none !important;
        background-color: #9D6BAC !important;
        border: none !important;
        box-shadow: none !important;
    }
`

export const SContainer = styled(Container)`
    background: #F3F1F1;
    padding: 20px;
    display: flex;
    flex-direction: row;
`

export const SGreyBlockTextPadding = styled.div`
    padding:20px;
`

const GreyBlock = (props) => {
    const { title, text, image, onButtonClick, buttonUrl, className, altLayout } = props;

    if (altLayout) {
        return (
            <div className={`grey-block ${className}`}>
                <SContainer>

                    <SGreyBlockTextPadding className="grey-block-text-padding grey-block-mobile">

                        <div className="title" dangerouslySetInnerHTML={{ __html: title }}>
                        </div>
                        <div className="text" dangerouslySetInnerHTML={{ __html: text }}>
                        </div>
                        {(onButtonClick || buttonUrl) && <SButton href={buttonUrl} onClick={onButtonClick}>READ MORE</SButton>}
                    </SGreyBlockTextPadding>

                    <div className="left grey-block-desktop">

                        <div className="title" dangerouslySetInnerHTML={{ __html: title }}>
                        </div>
                        <div className="text" dangerouslySetInnerHTML={{ __html: text }}>
                        </div>
                        {(onButtonClick || buttonUrl) && <SButton href={buttonUrl} onClick={onButtonClick}>READ MORE</SButton>}
                    </div>
                    <div className="right grey-block-desktop">
                        <img className="altImg" src={image} />
                    </div>


                </SContainer>
            </div>
        );
    }

    return (
        <SGreyBlock className={`grey-block ${className}`}>
            <SContainer>
                <div>
                    <img className="img" src={image} />
                </div>
                <SGreyBlockTextPadding className="grey-block-text-padding">

                    <div className="title" dangerouslySetInnerHTML={{ __html: title }}>
                    </div>
                    <div className="text" dangerouslySetInnerHTML={{ __html: text }}>
                    </div>
                    {(onButtonClick || buttonUrl) && <SButton href={buttonUrl} onClick={onButtonClick}>READ MORE</SButton>}
                </SGreyBlockTextPadding>
            </SContainer>
        </SGreyBlock>
    );
}

export default GreyBlock;