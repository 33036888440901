
import React, {useState, useEffect} from 'react'
import _ from 'lodash'
import { Container, Row, Col, Button, Carousel, Form, DropdownButton, Dropdown } from 'react-bootstrap'

export const Preload = (props) => {
    const preload = props.preload
    const [preloadCount, setPreloadCount] = useState(0)
    const [loading, setLoading] = useState(true)

    const increment = () => {
        setPreloadCount((prev) => {
            const next = prev + 1
            if (next == preload.length) {
                setLoading(false)
            }
            return next
        })
    }

    useEffect(() => {
        _.forEach(preload, (a) => {
          let asset = new Image()
          asset.src = a
          asset.onload = () => {
            increment()
          }
          asset.onerror = (e) => {
            console.error('Error loading: ', a)
            increment()
          }
        })
    }, [])

    if (!loading) {
        return (props.children)
    }

    return (
        <Container style={{ background: "#ffffff", marginTop: 400, width: 100 }}>
            <div class="lds-dual-ring"></div>
        </Container>
    )
}