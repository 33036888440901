import styled from "styled-components";

export const H1 = styled.h1`
  color: #9d6bac;
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 40px;
  line-height: 40px;
  font-weight: bold;
`;

export const H2 = styled.h2`
  color: #9d6bac;
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 20px;
`;
