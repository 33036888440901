import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const InnerContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  column-width: 200px;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

const ImgHyundai = styled.img`
  width: 200px;
  margin-right: 20px;
  margin-bottom: 40px;
  @media only screen and (max-width: 700px) {
    margin-right: 0px;
    flex-direction: column;
  }
`;

const ImgXCON = styled.img`
  width: 200px;
  margin-right: 20px;
  margin-bottom: 40px;
  @media only screen and (max-width: 700px) {
    margin-right: 0px;
    flex-direction: column;
  }
`;

const ImgSAPow = styled.img`
  width: 140px;
  margin-right: 40px;
  margin-bottom: 40px;
  @media only screen and (max-width: 700px) {
    margin-right: 0px;
    flex-direction: column;
  }
`;
const ImgVero = styled.img`
  width: 200px;
  margin-right: 40px;
  margin-bottom: 40px;
  @media only screen and (max-width: 700px) {
    margin-right: 0px;
    flex-direction: column;
  }
`;
const ImgBelgravia = styled.img`
  width: 110px;
  margin-right: 40px;
  margin-bottom: 40px;
  @media only screen and (max-width: 700px) {
    margin-right: 0px;
    flex-direction: column;
  }
`;

export const Sponsors = (props) => {
  return (
    <div className="partners">
      <div className="title">With thanks to our Sponsors:</div>
      <InnerContainer>
        <a href="https://www.peterpagehyundai.com.au/" target="_blank">
          <ImgHyundai src="/images/partnerPPH.jpg" />
        </a>
        <a href="https://xconvenience.com.au/" target="_blank">
          <ImgXCON src="/images/partnerXCON.svg" />
        </a>
        <a href="https://www.sapowernetworks.com.au/" target="_blank">
          <ImgSAPow src="/images/partnerSAPow.svg" />
        </a>
        <a href="https://www.veroguard.com.au/" target="_blank">
          <ImgVero src="/images/partnerVero.svg" />
        </a>
        <a href="https://belgraviaapparel.com/sportswear/" target="_blank">
          <ImgBelgravia src="/images/partnerBelgravia.svg" />
        </a>
      </InnerContainer>
    </div>
  );
};
