import { connect } from 'react-redux';

import {Welcome} from '../routes/Welcome';
import * as api from '../store/api'

const mapStateToProps = (state) => {
  return {
    ...state.service,
    
  };
}


const mapDispatchToProps = (dispatch) => ({
  test: (data) => {
    //dispatch(api.test(data));
  },
})


export default connect(mapStateToProps, mapDispatchToProps)(Welcome)
