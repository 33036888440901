import React, { useRef, useEffect, Component } from "react";
import styled from "styled-components";
import { BuyTicketsRow } from "./BuyTicketsRow";
import { useScrollPoint } from "../hooks";
import Confetti from "react-confetti";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0033a1;
`;

const TextContainer3 = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 30px;
  width: 100%;
  max-width: 1920px;
  margin-right: 15%;
  @media only screen and (max-width: 800px) {
    margin-right: 0%;
  }
`;

const SBuyTicketsRow = styled(BuyTicketsRow)`
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

export const WinnerText = styled.div`
  position: absolute;
  top: -200px;
  font-size: 36px;
  color: #ffffff;
  padding: 20px;
  background: #45bee2;
  z-index: 4;
`;

const Desc = styled.div`
  width: 100%;
  font-size: 50px;
  line-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  max-width: 1920px;
  font-weight: bold;
  z-index: 4;

  @media only screen and (max-width: 800px) {
    font-size: 25px;
    line-height: 25px;
  }
`;

export const FirstPrizeText = (props) => {
  const below = useScrollPoint(200);
  return (
    <Container className="first-prize-text-component">
      <SBuyTicketsRow top="-50px" {...props} />

      <TextContainer3 style={{ paddingTop: 50, paddingBottom: 50 }}>
        <Desc>
          WIN a Hyundai Santa Fe
          <img
            style={{ width: "8vw", marginRight: "2%", marginLeft: "2%" }}
            src="/images/orVector.svg"
          ></img>
          $45,000 CASH!
        </Desc>
      </TextContainer3>
    </Container>
  );
};
