import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Button, Form, Carousel, Modal } from "react-bootstrap";
import "babel-polyfill";

import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import StringHelpers from "../helpers/StringHelpers";
import Autosuggest from "react-autosuggest";
import Footer from "../components/Footer";
import PageHeader, { PageHeaderSpacer } from "../components/PageHeader";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { competitionState, CS_EARLY_BIRD_DEADLINE, CS_VIP_OPEN } from "../store/service";

// if validation is disabled, we just want to check all the pages without having to always enter correct data
// ensure this is always set to FALSE when commiting!
const DISABLE_VALIDATION = false;

const NumberOfTicketsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;

  @media only screen and (max-width: 500px) {
    justify-content: center;
    width: 100%;
  }
`;

const Input = styled.input`
  background-color: #c4d2e7;
  display: inline-block;
  padding: 6px;
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
  border: none;
  width: 80px;
  text-align: right;
  margin-left: 10px;
  margin-right: 10px;

  @media only screen and (max-width: 500px) {
    width: 120px;
  }
`;

const Probability = styled.div`
  background: url(/images/blue-button-bg.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 80px;
  width: 100%;
  max-width: 650px;
  color: #ffffff;

  @media only screen and (max-width: 800px) {
    height: 40px;
  }

  @media only screen and (max-width: 380px) {
    height: 60px;
  }
`;

const ProbabilityContent = styled.div`
  font-size: 24px;
  padding: 20px 20px 20px 30px;

  @media only screen and (max-width: 800px) {
    font-size: 14px;
    padding: 10px 10px 10px 20px;
  }
`;

const TicketDrawsRow = styled(Row)`
  font-style: italic;
  padding-top: 15px;
  display: block;

  @media only screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

const TopRow = styled(Row)`
  background: #0033a1;
  @media only screen and (max-width: 1000px) {
    margin-top: ${competitionState == CS_EARLY_BIRD_DEADLINE ? "55px" : "0px"};
  }
`;

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Required = (props) => <span className="required">*</span>;
/*
Test credit card:
5123456789012346
Just use any expiry in the future and any 3 digits for ccv
*/
const Steps = ({ step, onClick }) => {
  return (
    <TopRow className="buy-steps">
      <Container>
        <div class="steps-container">
          <Button onClick={() => step > 1 && onClick({ step: 1 })}>
            <div className={`step-container ${step >= 1 ? "active" : ""}`}>
              <div className="step">1</div>
              <div className="label">YOUR DETAILS</div>
            </div>
          </Button>

          <Button onClick={() => step > 2 && onClick({ step: 2 })}>
            <div className={`step-container ${step >= 2 ? "active" : ""}`}>
              <div className="step">2</div>
              <div className="label">PAYMENT</div>
              <div className="line" />
            </div>
          </Button>

          <Button onClick={() => step > 3 && onClick({ step: 3 })}>
            <div className={`step-container ${step >= 3 ? "active" : ""}`}>
              <div className="step">3</div>
              <div className="label">ORDER SUMMARY</div>
              <div className="line" />
            </div>
          </Button>
        </div>
      </Container>
    </TopRow>
  );
};

export default class BuyTickets extends Component {
  state = {
    step: 1,

    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    phone: "",

    address: "",
    suburb: "",
    postcode: "",
    state: "SA",

    cardName: "",
    cardNumber: "",
    expiryMonth: "01",
    expiryYear: "23",
    ccv: "",

    terms: false,
    optOutOfSMS: false,
    age: "",
    subscription: false,

    numberOfTickets: 2,

    addresses: [],
    checkingAddress: false,
    addressMessage: "",
    addressConfirmed: false,
    showAddressSelector: false,
    is18: false,

    purchasing: false,

    styles: {
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      phone: "",
      address: "react-autosuggest__input",
      suburb: "",
      postcode: "",
      cardName: "",
      cardNumber: "",
      ccv: "",
      terms: "",
      is18: "",
    },

    dataSource: [],
    value: "",
  };

  componentDidMount = () => {
    //ReactGA.plugin.require("ecommerce");

    ReactGA.event({
      category: "Lottery",
      action: "Buy Tickets Button",
      label: "Clicked Buy Tickets Button",
    });

    ReactGA.gtag("event", "view_item", {
      items: [
        {
          id: "1",
          name: "Ticket",
          quantity: this.state.numberOfTickets,
          price: this.state.numberOfTickets * 50,
        },
      ],
    });

    this.onAddressBlur = this.onAddressBlur.bind(this);
    this.onAddressFocus = this.onAddressFocus.bind(this);
    this.goBack = this.goBack.bind(this);

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    ReactPixel.pageView();
    try {
      ReactPixel.track("Purchase Ticket", { currency: "AUD" });
    } catch (e) {}
  };

  clearInputError(input) {
    if (input == "address") {
      this.setState({
        styles: update(this.state.styles, {
          [input]: { $set: "react-autosuggest__input" },
        }),
      });
    } else {
      this.setState({
        styles: update(this.state.styles, { [input]: { $set: "" } }),
      });
    }
  }

  checkAddress = (manuallyEntered) => {
    /* this.setState({
            firstName: StringHelpers.titleCase(this.state.firstName),
            lastName: StringHelpers.titleCase(this.state.lastName),
        }) */

    let self = this;
    var valid = true;
    var errorStyles = this.state.styles;
    let fieldsToCheck = ["firstName", "lastName", "email", "phone", "address" /* , "postcode", "suburb" */, "is18"];
    if (manuallyEntered) {
      fieldsToCheck = fieldsToCheck.concat(["postcode", "suburb"]);
    }

    _.forEach(fieldsToCheck, function (f) {
      if (f == "is18") {
        if (!self.state[f]) {
          valid = false;
          errorStyles[f] = "errorInput";
        }
      } else if (f == "address") {
        if (self.state[f] == "" || (self.state[f].length < 10 && manuallyEntered)) {
          valid = false;
          errorStyles[f] += " errorInput";
        }
      } else if (f == "email") {
        let invalidEmail = emailRegex.test(String(self.state[f]).toLowerCase());
        if (!invalidEmail) {
          valid = false;
          errorStyles[f] = "errorInput";
          errorStyles.email = "errorInput";
          errorStyles.confirmEmail = "errorInput";
        }
        if (self.state[f] != self.state.confirmEmail) {
          valid = false;
          errorStyles[f] = "errorInput";
          errorStyles.confirmEmail = "errorInput";
        }
      } else {
        if (self.state[f] == "") {
          valid = false;
          errorStyles[f] = "errorInput";
        }
      }
    });

    if (!DISABLE_VALIDATION) {
      if (!valid) {
        self.setState({
          styles: errorStyles,
        });
        return;
      }
    }

    // assume its all good to proceed
    if (DISABLE_VALIDATION || manuallyEntered) {
      this.setState({
        checkingAddress: false,
        addressConfirmed: true,
        showAddressSelector: false,
        step: 2,
      });
      window.scrollTo(0, 0);
      return;
    }

    this.setState({ checkingAddress: true });
    this.props.checkAddress(
      this.state.address + " " + this.state.suburb + " " + this.state.state + " " + this.state.postcode,
      (result) => {
        var addresses = [];
        var addressMessage = "";
        var showAddressSelector = false;
        var userFormattedAddress =
          this.state.address +
          ", " +
          this.state.suburb +
          " " +
          this.state.state +
          " " +
          this.state.postcode +
          ", Australia";

        // assume PO Box is correct, as the address matcher will never return matched on them
        const match = (result && result.matched) || this.state.address.toLowerCase().startsWith("po box");

        if (result && result.success && match) {
          // it is all good to proceed
          this.setState({
            checkingAddress: false,
            addressConfirmed: true,
            showAddressSelector: false,
            step: 2,
          });
          window.scrollTo(0, 0);

          ReactGA.gtag("event", "add_to_cart", {
            items: [
              {
                id: "1",
                name: "Ticket",
                quantity: self.state.numberOfTickets,
                price: self.state.numberOfTickets * 50,
              },
            ],
          });
        } else {
          addressMessage = "We could not validate the address you have entered. Please fill out the form below.";
          addresses = [
            {
              formatted_address: userFormattedAddress,
            },
          ];
          showAddressSelector = true;

          this.setState({
            checkingAddress: false,
            addressMessage: addressMessage,
            showAddressSelector: true,
          });
        }

        // display modal with addresses and allow user to select
        // upon selection, lock address fields and add edit button to allow the user to enter a new address
      }
    );
  };

  updateAddress = (a) => {
    var streetNumber = "";
    var street = "";
    var suburb = "";
    var postcode = "";
    var state = "";
    var address = "";

    if (a.address_components) {
      /*_.forEach(a.address_components, function (t) {
                if (t.types[0] == "street_number") {
                    streetNumber = t.long_name
                } else if (t.types[0] == "route") {
                    street = t.long_name
                } else if (t.types[0] == "locality") {
                    suburb = t.long_name
                } else if (t.types[0] == "administrative_area_level_1") {
                    state = t.short_name
                } else if (t.types[0] == "postal_code") {
                    postcode = t.long_name
                }
            })*/
      address = a.address_components.address_line_1;
      if (a.address_components.address_line_2) {
        address += ", " + a.address_components.address_line_2;
      }
      suburb = a.address_components.suburb;
      postcode = a.address_components.postcode;
      state = a.address_components.state;
    } else {
      address = this.state.address;
      suburb = this.state.suburb;
      postcode = this.state.postcode;
      state = this.state.state;
    }

    this.setState({
      //address: a.address_components ? streetNumber + " " + street : address,
      address: address,
      suburb: suburb,
      postcode: postcode,
      state: state,
      addressConfirmed: true,
      showAddressSelector: false,
    });
  };

  buyTickets = () => {
    let self = this;
    var valid = true;
    var errorStyles = this.state.styles;
    let fieldsToCheck = ["cardName", "cardNumber", "ccv", "terms"];
    _.forEach(fieldsToCheck, function (f) {
      if (f == "terms") {
        if (!self.state[f]) {
          valid = false;
          errorStyles[f] = "errorInput";
        }
      } else {
        if (self.state[f] == "") {
          valid = false;
          errorStyles[f] = "errorInput";
        }
      }
    });

    if (!DISABLE_VALIDATION) {
      if (!valid) {
        self.setState({
          styles: errorStyles,
        });
        return;
      }
    }

    let purchaseData = {
      firstName: self.state.firstName,
      lastName: self.state.lastName,
      email: self.state.email,
      phone: self.state.phone,

      address: self.state.address,
      suburb: self.state.suburb,
      postcode: self.state.postcode,
      state: self.state.state,

      cardName: self.state.cardName,
      cardNumber: self.state.cardNumber,
      expiryMonth: self.state.expiryMonth,
      expiryYear: self.state.expiryYear,
      cvc: self.state.ccv,

      ticketCount: self.state.numberOfTickets,

      optOutOfSMS: self.state.optOutOfSMS,
      age: self.state.age,
      subscription: self.state.subscription,
      paymentMethod: "website",
    };

    try {
      let urlParams = window.location.search;
      let referrer = "";
      try {
        if (urlParams.indexOf("referrer=") > -1) {
          referrer = urlParams.split("referrer=")[1].split("&")[0];
        }
        let utm_source;
        if (urlParams.indexOf("utm_source=") > -1) {
          utm_source = urlParams.split("utm_source=")[1].split("&")[0];
        }
        if (utm_source) {
          referrer = utm_source;
          if (urlParams.indexOf("utm_campaign=") > -1) {
            let utm_campaign = urlParams.split("utm_campaign=")[1].split("&")[0];
            if (utm_campaign) {
              referrer += ", campaign: " + utm_campaign;
            }
          }
          if (urlParams.indexOf("utm_content=") > -1) {
            let utm_content = urlParams.split("utm_content=")[1].split("&")[0];
            if (utm_content) {
              referrer += ", content: " + utm_content;
            }
          }
          if (urlParams.indexOf("utm_medium=") > -1) {
            let utm_medium = urlParams.split("utm_medium=")[1].split("&")[0];
            if (utm_medium) {
              referrer += ", medium: " + utm_medium;
            }
          }
        }
      } catch (e) {
        //
      }
      /* const urlParams = new URLSearchParams(window.location.search);
            
            let referrer = urlParams.get('referrer');
            if (urlParams.get('utm_source')) {
                referrer = urlParams.get('utm_source')
                if (urlParams.get('utm_campaign')) {
                    referrer += ", campaign: " + urlParams.get('utm_campaign')
                }
                if (urlParams.get('utm_content')) {
                    referrer += ", content: " + urlParams.get('utm_content')
                }
                if (urlParams.get('utm_medium')) {
                    referrer += ", medium: " + urlParams.get('utm_medium')
                }
            } */
      purchaseData.referrer = referrer || document.referrer;
    } catch (e) {
      purchaseData.referrer = document.referrer;
    }

    self.setState({ purchasing: true }, async () => {
      ReactGA.gtag("event", "begin_checkout", {
        items: [
          {
            id: "1",
            name: "Ticket",
            quantity: self.state.numberOfTickets,
            price: self.state.numberOfTickets * 50,
          },
        ],
        coupon: "",
      });

      self.props.purchase(purchaseData, async (result) => {
        console.log(result);

        // if validation is disabled, we just want to check all the pages
        // set setup a fake result
        if (DISABLE_VALIDATION) {
          result = {
            purchaseId: "DISABLE_VALIDATION_TEST_ID",
          };
        }

        self.setState({ purchasing: false });
        if (result.success == false || result.error) {
          console.log(result.message);
          alert(
            result.message
              ? "Error Message: " + result.message + ", Please try again."
              : "There was an error processing your purchase. Please try again."
          );
        } else {
          ReactGA.event({
            category: "Lottery",
            action: "Purchase Ticket",
            label: "Complete Purchase Ticket",
            value: purchaseData.ticketCount,
          });
          var ticketsValue = purchaseData.ticketCount * 50;
          ReactPixel.track("Complete Purchase Ticket", {
            currency: "AUD",
            value: ticketsValue,
          });
          window.scrollTo(0, 0);
          ReactGA.gtag("event", "purchase", {
            transaction_id: new Date().getTime(),
            affiliation: "CDFC Lottery Website",
            value: self.state.numberOfTickets * 50,
            currency: "AUD",
            tax: 0,
            shipping: 0,
            items: [
              {
                id: "1",
                name: "Ticket",
                quantity: self.state.numberOfTickets,
                price: self.state.numberOfTickets * 50,
              },
            ],
          });
          /*ReactGA.plugin.execute("ecommerce", "send");
          ReactGA.plugin.execute("ecommerce", "clear");*/
          self.setState({
            purchaseComplete: true,
            receiptNumber: result.purchaseId,
            purchaseResult: result,
            step: 3,
          });
        }
      });
    });
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.props.addressLookup(value.trim(), (result) => {
      if (result && result.success) {
        this.setState({
          dataSource: result.completions,
        });
      } else {
        this.setState({
          dataSource: [],
        });
      }
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      dataSource: [],
    });
  };

  renderSuggestion(suggestion) {
    return <span>{suggestion.full_address}</span>;
  }

  getSuggestionValue(suggestion) {
    return suggestion.full_address;
  }

  selectSuggestion = (event, suggestion) => {
    this.clearInputError("address");
    var suburb = "";
    var postcode = "";
    var state = "";
    var address = "";
    this.props.addressData(suggestion.suggestion.id, (result) => {
      if (result && result.success) {
        address = result.address_line_1;
        if (result.address_line_2) {
          address += ", " + result.address_line_2;
        }
        suburb = result.locality_name;
        postcode = result.postcode;
        state = result.state_territory;
        this.setState({
          address: address,
          suburb: suburb,
          postcode: postcode,
          state: state,
        });
      } else {
        //Do nothing?
      }
    });
  };

  onAddressBlur() {
    console.log(this.state.address);
    console.log(this.state.suburb);
    console.log(this.state.postcode);
    console.log(this.state.state);

    if (!this.state.address || this.state.address == "") this.setState({ address: this.state.value });
    //this.setState({ value: this.state.address == "" ? "" : this.state.address + ", " + this.state.suburb + " " + this.state.state + " " + this.state.postcode })
  }

  onAddressFocus = () => {
    this.setState({ address: "" });
  };

  onStepClick = ({ step }) => {
    // once purchasing/purchased we can't go back!
    if (this.state.purchasing || this.state.purchaseComplete || step >= 3) {
      return;
    }

    this.setState({ step });

    if (step === 1) {
      this.setState({
        /*is18: false, nfp: false, optOutOfSMS: false,*/ addressConfirmed: false,
      });
    }
  };

  goBack() {
    console.log(this, "back!");
    this.setState({ step: 1 });
  }

  render() {
    if (this.props.competitionClosed) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }

    let self = this;
    const inputProps = {
      placeholder: "e.g 1 Smith Street",
      value: this.state.value,
      onChange: this.onChange,
      //disabled: this.state.addressConfirmed,
      className: this.state.styles.address,
      autocomplete: "disable-autocomplete",
      onBlur: this.onAddressBlur,
      onFocus: this.onAddressFocus,
    };

    var addressSelector = [];
    if (this.state.showAddressSelector) {
      /*
            let addresses = []
            _.forEach(this.state.addresses, function (a) {
                addresses.push(
                    <div>
                        <Row>
                            <Col xs={6}>
                                {a.formatted_address}<br />
                            </Col>
                            <Col xs={6}>
                                <Button className="float-right" onClick={() => self.updateAddress(a)}>
                                    Confirm
                                </Button>
                            </Col>

                        </Row>
                    </div>
                )
            })
            addresses.push(
                <div>
                    <Button variant="info" className="float-right" onClick={() => self.setState({ showAddressSelector: false })}>
                        Cancel
                    </Button>
                </div>
            )*/
      addressSelector.push(
        <Modal aria-labelledby="contained-modal-title-vcenter" centered show={true}>
          <Modal.Header>
            <Modal.Title>Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/*addresses*/}

            <p>{this.state.addressMessage}</p>

            <Form.Group>
              <Form.Label>
                Address Line
                <Required />
              </Form.Label>
              <Form.Control
                type="address"
                autoComplete="off"
                onPaste={(e) => {
                  e.preventDefault();
                }}
                value={this.state.address}
                onChange={(e) => {
                  this.setState({ address: e.target.value });
                  this.clearInputError("address");
                }}
                className={this.state.styles.address}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Suburb
                <Required />
              </Form.Label>
              <Form.Control
                type="suburb"
                autoComplete="off"
                onPaste={(e) => {
                  e.preventDefault();
                }}
                value={this.state.suburb}
                onChange={(e) => {
                  this.setState({ suburb: e.target.value });
                  this.clearInputError("suburb");
                }}
                className={this.state.styles.suburb}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Postcode
                <Required />
              </Form.Label>
              <Form.Control
                type="postcode"
                autoComplete="off"
                onPaste={(e) => {
                  e.preventDefault();
                }}
                value={this.state.postcode}
                onChange={(e) => {
                  this.setState({
                    postcode: String(e.target.value)
                      .replace(/[^0-9]+/g, "")
                      .substring(0, 4),
                  });
                  this.clearInputError("postcode");
                }}
                className={this.state.styles.postcode}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                State
                <Required />
              </Form.Label>
              <Form.Control
                type="state"
                as="select"
                autoComplete="off"
                onPaste={(e) => {
                  e.preventDefault();
                }}
                value={this.state.state}
                onChange={(e) => {
                  this.setState({ state: e.target.value });
                  this.clearInputError("state");
                }}
                className={this.state.styles.state}
              >
                <option value="SA">SA</option>
                <option value="NT">NT</option>
                <option value="WA">WA</option>
                <option value="NSW">NSW</option>
                <option value="VIC">VIC</option>
                <option value="ACT">ACT</option>
                <option value="TAS">TAS</option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="submit-button float-md-right"
              disabled={this.state.checkingAddress}
              onClick={() => this.checkAddress(true)}
            >
              {this.state.checkingAddress ? (
                <div class="spin">
                  <FontAwesomeIcon spin icon={faCircleNotch} />
                </div>
              ) : null}
              Confirm Address
            </Button>
            {/*
                        <Button variant="info" className="float-right" onClick={() => self.setState({ showAddressSelector: false })}>
                            Confirm Details
                        </Button>
                        */}
          </Modal.Footer>
        </Modal>
      );
    }

    var personalDetails = [];
    if (!this.state.purchaseComplete) {
      personalDetails.push(
        <Row>
          <Col xs={12} className="pl-0 pr-0 paddingTop20">
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    First Name
                    <Required />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g John"
                    value={this.state.firstName}
                    onChange={(e) => {
                      this.setState({
                        firstName: String(e.target.value).replace(/[0-9!@#$%^&*()<>.]/g, ""),
                      });
                      this.clearInputError("firstName");
                    }}
                    //disabled={this.state.addressConfirmed}
                    className={this.state.styles.firstName}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group>
                  <Form.Label>
                    Last Name
                    <Required />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g Smith"
                    value={this.state.lastName}
                    onChange={(e) => {
                      this.setState({
                        lastName: String(e.target.value).replace(/[0-9!@#$%^&*()<>.]/g, ""),
                      });
                      this.clearInputError("lastName");
                    }}
                    //disabled={this.state.addressConfirmed}
                    className={this.state.styles.lastName}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label>
                    Email Address
                    <Required />
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="e.g john@example.com.au"
                    autoComplete="off"
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    value={this.state.email}
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                      this.clearInputError("email");
                      this.clearInputError("confirmEmail");
                    }}
                    //disabled={this.state.addressConfirmed}
                    className={this.state.styles.email}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>
                    Confirm Email Address
                    <Required />
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="e.g john@example.com.au"
                    autoComplete="off"
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    value={this.state.confirmEmail}
                    onChange={(e) => {
                      this.setState({ confirmEmail: e.target.value });
                      this.clearInputError("confirmEmail");
                      this.clearInputError("email");
                    }}
                    //disabled={this.state.addressConfirmed}
                    className={this.state.styles.email}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>
                    Mobile Number
                    <Required />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g 0412345678"
                    value={this.state.phone}
                    maxLength="12"
                    onChange={(e) => {
                      this.setState({
                        phone: String(e.target.value).replace(/[^0-9]+/g, ""),
                      });
                      this.clearInputError("phone");
                    }}
                    //disabled={this.state.addressConfirmed}
                    className={this.state.styles.phone}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }

    var addressBtn = (
      <Button
        className="submit-button float-md-right"
        disabled={this.state.checkingAddress}
        onClick={() => this.checkAddress(false)}
      >
        {this.state.checkingAddress ? (
          <div class="spin">
            <FontAwesomeIcon spin icon={faCircleNotch} />
          </div>
        ) : null}
        Confirm Details
      </Button>
    );
    /*
        if (this.state.addressConfirmed) {
            addressBtn = <Button className="submit-button float-right" disabled={this.state.checkingAddress} onClick={() => this.setState({ addressConfirmed: false })}>
                Change Details
        </Button>
        }*/
    var addressDetails = [];
    if (!this.state.purchaseComplete) {
      addressDetails.push(
        <div>
          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>
                  Address
                  <Required />{" "}
                  <em>
                    <span style={{ fontSize: 15 }}>(please enter your full address)</span>
                  </em>
                </Form.Label>
                <Autosuggest
                  suggestions={this.state.dataSource}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  onSuggestionSelected={this.selectSuggestion}
                  inputProps={inputProps}
                  className="form-control"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group style={{ marginTop: 7 }}>
                <label className="checkbox-label">
                  <Form.Check
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({ optOutOfSMS: e.target.checked });
                    }}
                    checked={this.state.optOutOfSMS}
                    //disabled={this.state.addressConfirmed}
                  />
                  Opt out of SMS alerts
                </label>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group style={{ marginTop: 7 }}>
                <label className={"checkbox-label " + this.state.styles.is18}>
                  <Form.Check
                    type="checkbox"
                    //disabled={this.state.addressConfirmed}
                    checked={this.state.is18}
                    onChange={(e) => {
                      this.setState({ is18: e.target.checked });
                      this.clearInputError("is18");
                    }}
                  />
                  I am 18 years of age or over
                  <Required />
                </label>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group>
                <div style={{ display: "inline-block", marginRight: 10 }}>Age</div>
                <Form.Control
                  as="select"
                  style={{ display: "inline-block", width: "auto" }}
                  onChange={(e) => {
                    this.setState({ age: e.target.value });
                  }}
                  value={this.state.age}
                  //disabled={this.state.addressConfirmed}
                >
                  <option value="-">-</option>
                  <option value="18-24">18 - 24</option>
                  <option value="25-34">25 - 34</option>
                  <option value="35-44">35 - 44</option>
                  <option value="45-54">45 - 54</option>
                  <option value="55-64">55 - 64</option>
                  <option value="65+">65 or more</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col md={12} className="text-center">
              {addressBtn}
            </Col>
          </Row>
        </div>
      );
    }

    var cardDetails = [];
    if (!this.state.purchaseComplete && this.state.addressConfirmed) {
      cardDetails.push(
        <div>
          <Row>
            <Col md={12} className="pl-0 pr-0 paddingTop20">
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      Name on Credit Card
                      <Required />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.cardName}
                      onChange={(e) => {
                        this.setState({ cardName: e.target.value });
                        this.clearInputError("cardName");
                      }}
                      className={this.state.styles.cardName}
                      disabled={this.state.purchasing}
                      placeholder="e.g John Smith"
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      Credit Card Number
                      <Required />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.cardNumber}
                      onChange={(e) => {
                        this.setState({ cardNumber: e.target.value });
                        this.clearInputError("cardNumber");
                      }}
                      className={this.state.styles.cardNumber}
                      disabled={this.state.purchasing}
                      placeholder="e.g 4200000000000000"
                    />
                    <img className="cardSupport float-right" src="/images/card-support.png" />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>
                      Expiry Month
                      <Required />
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        this.setState({ expiryMonth: e.target.value });
                      }}
                      disabled={this.state.purchasing}
                    >
                      <option value="01">Jan</option>
                      <option value="02">Feb</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label>
                      Expiry Year
                      <Required />
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        this.setState({ expiryYear: e.target.value });
                      }}
                      disabled={this.state.purchasing}
                    >
                      <option value="23">2023</option>
                      <option value="24">2024</option>
                      <option value="25">2025</option>
                      <option value="26">2026</option>
                      <option value="27">2027</option>
                      <option value="28">2028</option>
                      <option value="29">2029</option>
                      <option value="30">2030</option>
                      <option value="31">2031</option>
                      <option value="32">2032</option>
                      <option value="33">2033</option>
                      <option value="34">2034</option>
                      <option value="35">2035</option>
                      <option value="36">2036</option>
                      <option value="37">2037</option>
                      <option value="38">2038</option>
                      <option value="39">2039</option>
                    </Form.Control>
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group>
                    <Form.Label>
                      CCV
                      <Required />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={this.state.ccv}
                      onChange={(e) => {
                        this.setState({ ccv: e.target.value });
                        this.clearInputError("ccv");
                      }}
                      className={this.state.styles.ccv}
                      disabled={this.state.purchasing}
                      placeholder="e.g 333"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      );
    }

    var submitBtn = (
      <Button className="submit-button float-right" disabled={this.state.purchasing} onClick={this.buyTickets}>
        {this.state.purchasing ? (
          <div class="spin">
            <FontAwesomeIcon spin icon={faCircleNotch} />
          </div>
        ) : null}
        SUBMIT
      </Button>
    );
    var submitDetails = [];
    if (!this.state.purchaseComplete && this.state.addressConfirmed) {
      submitDetails.push(
        <div className="paddingBottom20">
          <Row style={{ marginTop: "20px" }}>
            <Col md={6}>
              <Form.Group>
                <label className={"checkbox-label " + this.state.styles.terms}>
                  <Form.Check
                    type="checkbox"
                    disabled={this.state.purchasing}
                    onChange={(e) => {
                      this.setState({ terms: e.target.checked });
                      this.clearInputError("terms");
                    }}
                  />
                  I agree to the{" "}
                  <a href="/terms-and-conditions" target="_blank">
                    terms and conditions
                  </a>
                  <Required />
                </label>
              </Form.Group>
            </Col>
            <Col md={6}>
              {submitBtn}
              {this.props.purchaseError ? <p className="errorInput">{this.props.purchaseError}</p> : null}
            </Col>
          </Row>
        </div>
      );
    }

    var selectTickets = [];
    if (!this.state.purchaseComplete) {
      selectTickets.push(
        <div>
          <Container>
            <div className="subtitle lg">TICKET ORDER FORM</div>
            <Row className="select-tickets">
              <NumberOfTicketsContainer>
                <Button
                  className="number-of-tickets-btn"
                  onClick={() =>
                    this.setState({
                      numberOfTickets: this.state.numberOfTickets - (this.state.numberOfTickets == 1 ? 0 : 1),
                    })
                  }
                >
                  <div style={{ marginTop: -8 }}>
                    <FontAwesomeIcon icon={faMinus} color="#db0032" />
                  </div>
                </Button>
                {/*<div className="number-of-tickets">{this.state.numberOfTickets}</div>*/}
                <Input
                  value={this.state.numberOfTickets}
                  onChange={(e) => {
                    const numberOfTickets = e.target.value.replace(/[^\d]/, "");
                    this.setState({ numberOfTickets });
                  }}
                  onBlur={(e) => {
                    let numberOfTickets = Number(e.target.value);
                    numberOfTickets = Math.min(numberOfTickets, 100);
                    numberOfTickets = Math.max(1, numberOfTickets);
                    this.setState({ numberOfTickets });
                  }}
                />
                <Button
                  className="number-of-tickets-btn"
                  onClick={() =>
                    this.setState({
                      numberOfTickets: this.state.numberOfTickets + 1,
                    })
                  }
                >
                  <div style={{ marginTop: -8 }}>
                    <FontAwesomeIcon icon={faPlus} color="#db0032" />
                  </div>
                </Button>
              </NumberOfTicketsContainer>
              <div className="totalAmountHolder">
                <div className="totalAmount">Total Amount:</div>{" "}
                <span className="number-of-tickets">${this.state.numberOfTickets * 50}</span>
              </div>
            </Row>
            <Row className="odds-calculator">
              <Probability className="probability">
                <ProbabilityContent className="probability-content">
                  <span>Your odds of winning the First Prize:&nbsp;</span>
                  <span style={{ fontWeight: "bold" }}>
                    1 in {Number((3750 / this.state.numberOfTickets).toFixed(0)).toLocaleString()}
                  </span>
                </ProbabilityContent>
              </Probability>
            </Row>
            <TicketDrawsRow>
              <div dangerouslySetInnerHTML={{ __html: this.props.ticketDraws }} />
            </TicketDrawsRow>
          </Container>
        </div>
      );
    }

    var thankyou = [];
    if (this.state.purchaseComplete) {
      let p = this.state.purchaseResult;
      let ticketStr = "";
      for (let i = p.ticketIndex; i < p.ticketIndex + p.ticketCount; i++) {
        if (ticketStr != "") ticketStr += ", ";
        ticketStr += "CDFC001_" + StringHelpers.pad(i, 5);
        console.log(i);
      }
      thankyou.push(
        <Row>
          <Col md={12}>
            <p style={{ color: "#000000" }}>
              <strong>Name</strong>: {this.state.firstName} {this.state.lastName}
              <br />
              <strong>Address</strong>: {this.state.address}, {this.state.suburb} {this.state.state}{" "}
              {this.state.postcode}
              <br />
              <strong>Email</strong>: {this.state.email}
              <br />
              <strong>Phone</strong>: {this.state.phone}
              <br />
              <strong>Number of tickets purchased</strong>: {this.state.numberOfTickets}
              <br />
              <strong>Ticket Numbers</strong>: {ticketStr}
              <br />
              <strong>Receipt number</strong>: {this.state.receiptNumber}
              <br />
              <strong>Total amount</strong>: ${Number(this.state.numberOfTickets) * 50}
              <br />
            </p>
            <p style={{ color: "#000000", textAlign: "center" }}>
              Your official ticket(s) have been emailed to <b>{this.state.email}</b>.
            </p>
            <p style={{ color: "#000000", textAlign: "center" }}>
              Thank you for your support and good luck in the draws!
            </p>
          </Col>
        </Row>
      );
    }

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Buy Tickets | Central District Football Club Lottery</title>
          <link rel="canonical" href="https://cdfclottery.com.au" />
          <meta
            name="description"
            content="You win, you choose in the Central District Football Club Lottery. WIN a CAR or $45K CASH and help support Central District Football Club."
          />
        </Helmet>
        <PageHeader showBack={true} buttonClick={this.state.step == 2 ? this.goBack : null} {...this.props} />
        <PageHeaderSpacer />

        <Container className="buy-tickets mainContainer noPadding">
          {addressSelector}

          <Steps step={this.state.step} onClick={this.onStepClick} />

          {this.state.step != 3 && <div className="select-tickets-container">{selectTickets}</div>}

          {this.state.step === 1 && (
            <div className="buy-tickets-content-container your-details">
              <Container>
                <div className="subtitle lg">YOUR DETAILS</div>
                {personalDetails}
                {addressDetails}
              </Container>
            </div>
          )}

          {/* Bellow Div is for the error on 18/11/2022, uncomment above when fixed */}

          {/* <div
            style={{
              maxWidth: "75%",
              backgroundColor: "white",
              textAlign: "center",
              margin: "auto",
              paddingTop: "5%",
              paddingBottom: "5%",
            }}
          >
            <h2>
              We are currently experiencing issues with our payment gateway. Please check back soon to purchase your
              tickets.
            </h2>
          </div> */}

          {this.state.step === 2 && (
            <div className="buy-tickets-content-container payment">
              <Container>
                <div className="subtitle lg">PAYMENT</div>
                {cardDetails}
                {submitDetails}
              </Container>
            </div>
          )}

          {this.state.step === 3 && (
            <div className="buy-tickets-content-container order-summary">
              <Container>
                <div className="subtitle lg">ORDER SUMMARY</div>
                {thankyou}
              </Container>
            </div>
          )}
        </Container>

        <Footer />
      </div>
    );
  }
}
