// Mailchimp email form
// with some manual validation from here:
//
//  https://css-tricks.com/form-validation-part-4-validating-mailchimp-subscribe-form/
//
import React, { useEffect } from 'react'

// Display the form status
const displayMailChimpStatus = (data) => {
    const errorStatus = document.querySelector('#mce-error-response')
    const successStatus = document.querySelector('#mce-success-response')

    if (data.result === 'error') {
        errorStatus.innerHTML = data.msg;
        errorStatus.style.display = 'block';
    } else {
        successStatus.innerHTML = data.msg;
        successStatus.style.display = 'block';
    }
};
window.displayMailChimpStatus = displayMailChimpStatus

// Serialize the form data into a query string
// Forked and modified from https://stackoverflow.com/a/30153391/1293256
const serialize = (form) => {
    // Setup our serialized data
    var serialized = '';

    // Loop through each field in the form
    for (let i = 0; i < form.elements.length; i++) {

        var field = form.elements[i];

        // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
        if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

        // Convert field data to a query string
        if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
            serialized += '&' + encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value);
        }
    }

    return serialized;

};

// Submit the form
const submitMailChimpForm = (form) => {
    // Get the Submit URL
    var url = form.getAttribute('action');
    url = url.replace('/post?u=', '/post-json?u=');
    url += serialize(form) + '&c=displayMailChimpStatus';

    // Create script with url and callback (if specified)
    var script = window.document.createElement( 'script' );
    script.src = url;

    // Insert script tag into the DOM (append to <head>)
    var ref = window.document.getElementsByTagName( 'script' )[ 0 ];
    ref.parentNode.insertBefore( script, ref );

    // After the script is loaded (and executed), remove it
    script.onload = function () {
        this.remove();
    };
};

export const EmailForm = (props) => {
    useEffect(() => {
        document.addEventListener('submit', function (event) {

            // Only run on forms flagged for validation
            if (!event.target.classList.contains('validate')) {
                return;
            }
        
            // Prevent form from submitting
            event.preventDefault();
        
            // Get all of the form elements
            var fields = event.target.elements;
        /*
            // Validate each field
            // Store the first field with an error to a variable so we can bring it into focus later
            var error, hasErrors;
            for (var i = 0; i < fields.length; i++) {
                error = hasError(fields[i]);
                if (error) {
                    showError(fields[i], error);
                    if (!hasErrors) {
                        hasErrors = fields[i];
                    }
                }
            }
        
            // If there are errrors, don't submit form and focus on first element with error
            if (hasErrors) {
                hasErrors.focus();
            }
        */
            // Otherwise, let the form submit normally
            // You could also bolt in an Ajax form submit process here
            submitMailChimpForm(event.target);

        }, false);
    }, [])

    return (
        <div className="mailchimpHolder">
            {<div className="mailchimp" dangerouslySetInnerHTML={{ __html: props.mailchimp }}></div>}
        </div>
    )
}