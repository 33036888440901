import React from "react";
import { Helmet as RHelmet } from "react-helmet";

export const Helmet = (props) => {
  return (
    <RHelmet>
      <meta charSet="utf-8" />
      <title>
        {props.title}
        {(props.title && " | ") || ""}Central District Football Club Lottery
      </title>
      <link rel="canonical" href={`https://cdfclottery.com.au${props.link}`} />
      <meta name="description" content={props.description} />
    </RHelmet>
  );
};
