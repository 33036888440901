import moment from "moment";

export const CS_PRELAUNCH = 0;
export const CS_VIP_OPEN = 1;
export const CS_VIP_CLOSED = 2;
export const CS_VIP_DRAWN = 3;

export const CS_EARLY_BIRD_OPEN = 4;
export const CS_EARLY_BIRD_DEADLINE = 5;
export const CS_EARLY_BIRD_CLOSED = 6;
export const CS_EARLY_BIRD_DRAWN = 7;

export const CS_FIRST_PRIZE_DEADLINE = 8;
export const CS_COMPETITION_CLOSED = 9;
export const CS_FIRST_PRIZE_DRAWN = 10;

export const CS_EB_CTA = 11;


const year = 2023;


export const competitionState = moment(new Date()) <= moment(new Date(year, 6, 28, 23, 59)) ? CS_FIRST_PRIZE_DEADLINE : CS_COMPETITION_CLOSED;





const allStateData = [
  {
    competitionState: CS_PRELAUNCH,
    announcement: "COMING SOON!",
    competitionClosed: true,
  },
  {
    endDate: moment(new Date(year, 8, 15, 10, 59, 59)),
    competitionState: CS_VIP_OPEN,
    countdown: moment(new Date(year, 8, 15, 10, 59, 59)),
    announcement: "$5K VIP Draw closing in",
    secondaryAnnouncement: "",
    ticketDraws:
      "Your ticket will be included in the following draws: $2,000 Early Bird Prize Draw, $52,990 First Prize Draw",
    competitionClosed: false,
  },
  {
    competitionState: CS_VIP_CLOSED,
    //announcement: 'YOU WIN, YOU CHOOSE! WIN a CAR, COMBO or $150K CASH',
    announcement:
      "You win, you choose in the Central District Football Club Lottery. Win $45,000 in CASH, OR a Car worth $52,990!!",
    ticketDraws:
      "Your ticket will be included in the following draws: $2,000 Early Bird Prize Draw, $52,990 First Prize Draw",
    competitionClosed: false,
  },
  {
    competitionState: CS_VIP_DRAWN,
    announcement: "WIN $150K or a Prado + Jayco Combo!",
    ticketDraws:
      "Your ticket will be included in the following draws: $2,000 Early Bird Prize Draw, $52,990 First Prize Draw",
    competitionClosed: false,
  },
  {
    competitionState: CS_EB_CTA,
    announcement: "$2000 Early Bird Prize! Deadline Fast Approaching!",
    ticketDraws:
      "Your ticket will be included in the following draws: $2,000 Early Bird Prize Draw, $52,990 First Prize Draw.",
    competitionClosed: false,
  },
  {
    competitionState: CS_EARLY_BIRD_OPEN,
    announcement: "Early Bird Deadline Midnight 9th November",
    secondaryAnnouncement: "WIN $2K THIS THURSDAY!",
    ticketDraws:
      "Your ticket will be included in the following draws: $2,000 Early Bird Prize Draw, $52,990 First Prize Draw",
    competitionClosed: false,
  },
  {
    endDate: moment(new Date(year, 2, 28, 23, 59, 59)), // 11:50pm 1 nov
    competitionState: CS_EARLY_BIRD_DEADLINE,
    countdown: moment(new Date(year, 2, 28, 23, 59, 59)), // 11:50pm 1 nov
    announcement: "$20K EARLY BIRD DEADLINE",
    secondaryAnnouncement: "",
    ticketDraws:
      "Your ticket will be included in the following draws: $2,000 Early Bird Prize Draw, $52,990 First Prize Draw",
    competitionClosed: false,
  },
  {
    competitionState: CS_EARLY_BIRD_CLOSED,
    announcement:
      (new Date() > new Date(2022, 3, 1, 23, 59, 59) ? "Over 90%" : "Over 80%") +
      " sold! WIN $150K or a Prado + Jayco Combo!",
    ticketDraws: "Your ticket(s) will be included in our First Prize Draw to WIN $150K or a Prado + Jayco Combo.",
    competitionClosed: false,
  },
  {
    competitionState: CS_EARLY_BIRD_DRAWN,
    announcement: "LESS THAN 2,000 TICKETS LEFT!",
    ticketDraws: "Your ticket will be included in the following draws: $52,990 First Prize Draw",
    competitionClosed: false,
  },
  {
    endDate: moment(new Date(year, 6, 28, 23, 59)), // 11:50pm 30 nov
    competitionState: CS_FIRST_PRIZE_DEADLINE,
    countdown: moment(new Date(year, 6, 28, 23, 59)), // 11:50pm 30 nov
    announcement: "FINAL DEADLINE",
    secondaryAnnouncement: "TICKETS OVER 95% SOLD!",
    ticketDraws: "Your ticket will be included in the following draws: $52,990 First Prize Draw.",
    competitionClosed: false,
  },
  {
    competitionState: CS_COMPETITION_CLOSED,
    announcement: "Congratulations to our first prize winner, Greg H with ticket number CDFC001_01100",
    competitionClosed: true,
  },
  {
    competitionState: CS_FIRST_PRIZE_DRAWN,
    announcement: "WINNER ANNOUNCED! THANK YOU FOR YOUR SUPPORT SOUTH AUSTRALIA!",
    competitionClosed: true,
  },
];

const getStateData = (competitionState) => {
  const stateData = allStateData.find((s) => s.competitionState == competitionState);

  // if the curent state has a 'endDate' and now is beyond endDate jump to the next state!
  const today = moment();
  if (stateData.endDate && today.isAfter(stateData.endDate)) {
    return getStateData(competitionState + 1);
  }
  return stateData;
};
const stateData = getStateData(competitionState);

const initialState = {
  competitionState,
  ...stateData,
  message: "",
  purchaseComplete: false,
  purchaseError: "",
  receiptNumber: "",
  drawComplete: false,
  winnersSearchResults: null,

  vipWinner: {
    name: "Bob Jones",
    suburb: "Lockleys",
    ticket: "#XXXX",
  },

  earlyBirdWinner: {
    name: "Brooke C.",
    suburb: "Evandale",
    ticket: "#XXXXX",
  },

  firstPrizeWinner: {
    name: "John Do",
    suburb: "Lockleys",
    ticket: "#XXXX",
  },

  mailchimp: `
<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
<style type="text/css">
#mc_embed_signup{background:#fff; clear:left; }
/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
  We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="https://wchfoundation.us10.list-manage.com/subscribe/post?u=73e0fd36299d190cb3e9d2f7d&amp;id=76e6aeb489" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self" novalidate>
    <div id="mc_embed_signup_scroll">
<div class="title">Join our VIP mailing list</div>
<div class="text" style="color:#9d6daf!important; font-size:20px; font-weight:500;">Sign up for exclusive access to VIP tickets and bonus draws.</div>
<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="row">
<div class="col-md-6">
<label for="mce-FNAME">First Name  <span class="asterisk">*</span>
</label>
<input type="text" value="" name="FNAME" class="form-control required" id="mce-FNAME">
</div>
<div class="col-md-6">
<label for="mce-LNAME">Last Name  <span class="asterisk">*</span>
</label>
<input type="text" value="" name="LNAME" class="form-control required" id="mce-LNAME">
</div>
<div class="col-md-6">
<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
<input type="email" value="" name="EMAIL" class="form-control required email" id="mce-EMAIL">
</div>
<div class="col-md-6">
<label for="mce-PHONE">Mobile Number</label>
<input type="text" name="PHONE" class="form-control " value="" id="mce-PHONE">
</div>
</div>
<div id="mce-responses" class="clear">
<div class="response" id="mce-error-response" style="display:none"></div>
<div class="response" id="mce-success-response" style="display:none"></div>
</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_73e0fd36299d190cb3e9d2f7d_76e6aeb489" tabindex="-1" value=""></div>
    <div class="clear" style="margin-top:25px;">
    
    <div class="button-with-bg-container">
    <input style="outline:none; width:200px; height:70px; max-width:200px; background-color:transparent; border:none; display:flex; flex-direction:column; justify-content:center; align-items:center;" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button-with-bg-purple">
    </div>
    </div>
    </div>
</form>
</div>
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]="FNAME";ftypes[1]="text";fnames[2]="LNAME";ftypes[2]="text";fnames[0]="EMAIL";ftypes[0]="email";fnames[4]="PHONE";ftypes[4]="phone";}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup--></div>
`,
};

const service = (state = initialState, action) => {
  switch (action.type) {
    case "API_LOADING":
    case "API_IDLE":
      return { ...state, apiStatus: action.type };
    case "TEST":
      return { ...state, apiStatus: "API_IDLE", message: action.data };
    case "CLEAR_MESSAGE":
      return { ...state, message: "" };
    case "PURCHASE":
      return {
        ...state,
        apiStatus: "API_IDLE",
        purchaseComplete: true,
        receiptNumber: action.data.purchaseId,
      };
    case "PURCHASE_ERROR":
      return { ...state, apiStatus: "API_IDLE", purchaseError: action.data };
    case "CLEAR_PURCHASE_ERROR":
      return { ...state, purchaseError: "" };
    case "CHECK_DRAW_STATUS":
      return { ...state, drawComplete: action.data };
    case "CLEAR_WINNERS_SEARCH":
      return { ...state, winnersSearchResults: null };
    case "SEARCH_WINNERS":
      return { ...state, winnersSearchResults: action.data };
    //return { ...state, apiStatus: "API_IDLE" };
    default:
      return state;
  }
};

export default service;
