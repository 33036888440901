import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import ButtonWithBg from "./ButtonWithBg";
import { Button } from "react-bootstrap";

export const BuyTicketsButton = (props) => {
  const { competitionClosed } = props;
  if (competitionClosed) {
    return <ButtonWithBg href="#signup" label="VIP SIGN UP" />;
  }

  return <ButtonWithBg href={"/buy-tickets" + window.location.search} />;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 0px;
  overflow: visible;
  postiion: relative;
`;

const AbsContainer = styled.div`
  position: absolute;
  z-index: 100;
  top: ${(props) => props.top || "-40px"};
`;

export const BuyTicketsRow = (props) => {
  return (
    <Container className={props.className}>
      <AbsContainer top={props.top}>
        <BuyTicketsButton {...props} />
      </AbsContainer>
    </Container>
  );
};

const FixedBottomContainer = styled(Button)`
  height: 50px;
  position: fixed;
  bottom: 0%;
  width: 100%;
  background-color: #ed1c24 !important;
  opacity: 1;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 24px;
  font-weight: 700;

  border: none;
  border-radius: 0px;
  transition: transform 0.1s ease-out;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none !important;
    background-color: #ed1c24 !important;
    border: none !important;
    box-shadow: none !important;
  }

  &:hover {
    transform: scale(1.1);
  }

  @media only screen and (min-width: 801px) {
    display: none;
  }
`;

export const BuyTicketsFixedBottom = (props) => {
  const { competitionClosed } = props;
  if (competitionClosed) {
    return (
      <FixedBottomContainer href="/#signup">VIP SIGN UP</FixedBottomContainer>
    );
  }

  return (
    <FixedBottomContainer href={"/buy-tickets" + window.location.search}>
      BUY TICKETS
    </FixedBottomContainer>
  );
};
