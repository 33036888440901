import React, { Component } from "react";
import _ from "lodash";
import {
  Container,
  Row,
  Col,
  Button,
  Carousel,
  Form,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import "babel-polyfill";
import moment from "moment";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import "../styles/global.css";
import BuyTickets from "../containers/BuyTicketsContainer";
import Countdown from "../components/Countdown";
import Footer from "../components/Footer";
import ButtonWithBg from "../components/ButtonWithBg";
import PageHeader, { PageHeaderSpacer } from "../components/PageHeader";
import GreyBlock from "../components/GreyBlock";
import { ScrollToHash } from "../components/ScrollToHash";
import {
  CS_PRELAUNCH,
  CS_VIP_DRAWN,
  CS_EARLY_BIRD_DRAWN,
  CS_FIRST_PRIZE_DRAWN,
  CS_COMPETITION_CLOSED,
} from "../store/service";
import { Prelaunch } from "../components/Prelaunch";
import { Contact } from "../components/Contact";
import {
  Helmet,
  FirstPrize,
  EarlyBirdPrize,
  Preload,
  FirstPrizeText,
  EarlyBirdPrizeText,
  Sponsors,
  BuyTicketsFixedBottom,
  CashPrize,
  YouWinYouChoose,
  NovitaBanner,
  CDFCBanner,
} from "../components";

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});
ReactPixel.pageView();

const preload = ["/images/close.svg", "/images/menu.svg", "/images/phone.svg"];

export const Welcome = (props) => {
  const { competitionState } = props;
  let vipWinner = competitionState >= CS_VIP_DRAWN ? props.vipWinner : null;
  let earlyBirdWinner =
    competitionState >= CS_EARLY_BIRD_DRAWN ? props.earlyBirdWinner : null;
  let firstPrizeWinner =
    competitionState >= CS_FIRST_PRIZE_DRAWN ? props.firstPrizeWinner : null;

  switch (competitionState) {
    case CS_PRELAUNCH:
      return <Prelaunch {...props} />;
  }

  return (
    <div>
      <Helmet
        title=""
        link=""
        description=" You win, you choose in the Central District Football Club Lottery. Win $45,000 in CASH, OR a Car worth $52,990!!"
      />

      <Preload preload={preload}>
        <PageHeader showBack={false} {...props} />
        <PageHeaderSpacer />
        <BuyTicketsFixedBottom competitionClosed={props.competitionClosed} />

        <Container className="mainContainer noPadding">
          <div className="mainTopMargin">
            <FirstPrize />
            <FirstPrizeText competitionClosed={props.competitionClosed} />
            <YouWinYouChoose />
            <CashPrize />
            {/* <EarlyBirdPrizeText competitionClosed={props.competitionClosed} /> */}
            <CDFCBanner></CDFCBanner>
            <NovitaBanner></NovitaBanner>
            <div className="contact-and-partners">
              <Container>
                <Sponsors />
                <a name="signup" id="signup" />
                <div className="divider" />
                <Contact />
              </Container>
            </div>
          </div>
          <Footer />
        </Container>
        <ScrollToHash />
      </Preload>
    </div>
  );
};
