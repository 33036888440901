import { connect } from 'react-redux';

import * as api from '../store/api'
import BuyTickets from '../routes/BuyTickets';
import { stat } from 'fs';

const mapStateToProps = (state) => {
  return {
    ...state.service,
  };
}


const mapDispatchToProps = (dispatch) => ({
  checkAddress: (addressStr, callback) => {
    dispatch(api.checkAddress(addressStr)).then(callback)
  },
  addressLookup: (addressStr, callback) => {
    dispatch(api.addressLookup(addressStr)).then(callback)
  },
  addressData: (addressId, callback) => {
    dispatch(api.addressData(addressId)).then(callback)
  },
  purchase: (data, callback) => {
    dispatch(api.postData("/buytickets", "BUY_TICKETS", data, true)).then(callback);
  },
})


export default connect(mapStateToProps, mapDispatchToProps)(BuyTickets)
