import React from 'react'
import {EmailForm} from './EmailForm'
import Confetti from "react-confetti";

export const Prelaunch = (props) => {
    return (
        <div className='prelaunch'>
            <Confetti class="confettiOverlay" numberOfPieces={500} />
            <div className='message-container'>
                <div className='message'>COMING SOON!</div>
                <img className='logo' src='/images/mhl-logo.png' />
                <div className='contact'>
                    <EmailForm {...props}/>
                </div>
            </div>
        </div>
    )
}