import React, { Component } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";

const Container = styled.div`
  display: inline-block;
  z-index: 999;
`;

const SButton = styled(Button)`
  background: url(/images/buyVector.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  height: 70px;
  width: 356px;
  max-width: 356px;
  margin: 0px !important;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #fff;
  font-size: 24px;
  font-weight: 700;

  border: none;
  transition: transform 0.1s ease-out;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none !important;
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  &:hover {
    transform: scale(1.1);
  }

  &.back {
    background: url(/images/blue-button-bg.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 70px;
    width: 120px;
    max-width: 120px;
  }

  &.blue {
    background: url(/images/blueButtonVector.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.small {
    width: 180px;
    font-size: 16px;
    line-height: 16px;
  }
`;

const ButtonWithBg = (props) => {
  return (
    <Container className={`button-with-bg-container-temp ${props.className}`}>
      <SButton
        href={props.href}
        onClick={props.onClick}
        className={`button-with-bg-temp ${props.className}`}
      >
        {props.label || "BUY TICKETS"}
      </SButton>
    </Container>
  );
};

export default ButtonWithBg;
