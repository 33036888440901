import fetch from "cross-fetch";
import _ from "lodash";
import aws4 from "aws4";

import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

ReactGA.initialize("G-YZT8LPSR1S");
const advancedMatching = {};
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

// Adding CDFC tracking pixel??
ReactPixel.init("895062294790267", advancedMatching, options);

const isLocal = window.location.href.indexOf("sandbox") > -1;

// AWS
const API_ENDPOINT = isLocal ? "127.0.0.1:3000" : "api.cdfclottery.com.au";
const API_STAGE = "/";
const API_PROTOCOL = isLocal ? "http://" : "https://";
const API_REGION = "ap-southeast-2";

const ADDRESS_FINDER_KEY = "6KGTWAJVLC4R9U3MHDXB";
/* API methods */

function onGetData(type, data) {
  return {
    type: type,
    data: data,
    receivedAt: Date.now(),
  };
}

export function getData(path, type, queryString = "", signRequest = false) {
  return function (dispatch) {
    dispatch({ type: "API_LOADING" });

    return new Promise(async (resolve, reject) => {
      let response = await _getData(path, type, queryString, signRequest);
      if (response) {
        dispatch(onGetData(type, response));
        resolve(response);
      } else {
        dispatch(onGetData(type, null));
        resolve(false);
      }
    });
  };
}

async function _getData(path, type, queryString = "") {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(API_PROTOCOL + API_ENDPOINT + path + queryString);
    if (!res || !res.ok) {
      console.log("error with getData", type, res);
      resolve(false);
    } else {
      console.log("res", res);
      let data;
      try {
        data = await res.json();
        if (data) {
          resolve(data);
        } else {
          resolve(false);
        }
      } catch (e) {
        console.log("Error", e);
        resolve(false);
      }
    }
  });
}

function onPostData(type, data) {
  return {
    type: type,
    data: data,
    receivedAt: Date.now(),
  };
}

export function postData(path, type, data, signRequest = false) {
  return function (dispatch) {
    dispatch({ type: "API_LOADING" });

    return new Promise(async (resolve, reject) => {
      let response = await _postData(path, type, data, signRequest);
      if (response) {
        dispatch(onPostData(type, response));
        resolve(response);
      } else {
        resolve(false);
      }
    });
  };
}

async function _postData(path, type, data) {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(API_PROTOCOL + API_ENDPOINT + path, {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(data),
    });
    if (!res || !res.ok) {
      console.log("error with getData", res);
      resolve(false);
    } else {
      console.log("res", res);

      const data = await res.json();
      if (data) {
        resolve(data);
      } else {
        resolve(false);
      }
    }
  });
}

export function checkAddress(addressStr) {
  // let apiKey = "AIzaSyDArF9jqOq6xB9rncT7mv1FDWr-DbCbtB8";
  /*let apiKey = "AIzaSyC-a7pAzC9NkDUXhWReCpr7MAvfv_bSr8A";
  let url =
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
    addressStr +
    "&key=" +
    apiKey;*/
  // let key = "T3CDUAV6GL7Y9QXEKBF4";
  // let url = "https://api.addressfinder.io/api/au/address/cleanse?key=" + key + "&q=" + addressStr + "&format=json";
  let url =
    "https://api.addressfinder.io/api/au/address/cleanse?key=" +
    ADDRESS_FINDER_KEY +
    "&q=" +
    addressStr +
    "&format=json";

  return function (dispatch) {
    return new Promise((resolve, reject) => {
      var req = async () => {
        try {
          const rawResponse = await fetch(url, {
            method: "GET",
          });
          const content = await rawResponse.json();
          return content;
        } catch (err) {
          console.error("checkAddress error", err);
          resolve(null);
        }
      };

      req().then((response) => {
        console.log("checkAddress response", response);
        resolve(response);
      });
    });
  };
}

export function addressData(id) {
  // let apiKey = "AIzaSyDArF9jqOq6xB9rncT7mv1FDWr-DbCbtB8";
  /*let apiKey = "AIzaSyC-a7pAzC9NkDUXhWReCpr7MAvfv_bSr8A";
  let url =
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
    addressStr +
    "&key=" +
    apiKey;*/
  /*let key = "T3CDUAV6GL7Y9QXEKBF4";
  let url = "https://api.addressfinder.io/api/au/address/info?key=" + key + "&id=" + id + "&format=json";*/
  let url =
    "https://api.addressfinder.io/api/au/address/info?key=" +
    ADDRESS_FINDER_KEY +
    "&id=" +
    id +
    "&format=json";

  return function (dispatch) {
    return new Promise((resolve, reject) => {
      var req = async () => {
        try {
          const rawResponse = await fetch(url, {
            method: "GET",
          });
          const content = await rawResponse.json();
          return content;
        } catch (err) {
          console.error("addressData error", err);
          resolve(null);
        }
      };

      req().then((response) => {
        console.log("addressData response", response);
        resolve(response);
      });
    });
  };
}

export function addressLookup(value) {
  // let key = "AIzaSyDArF9jqOq6xB9rncT7mv1FDWr-DbCbtB8";
  /*let key = "AIzaSyC-a7pAzC9NkDUXhWReCpr7MAvfv_bSr8A";
  let url = "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=" + value + "&key=" + key;*/
  let url =
    "https://api.addressfinder.io/api/au/address/autocomplete?key=" +
    ADDRESS_FINDER_KEY +
    "&q=" +
    value +
    "&format=json";

  return function (dispatch) {
    return new Promise((resolve, reject) => {
      var req = async () => {
        try {
          const rawResponse = await fetch(url, {
            method: "GET",
          });
          console.log(rawResponse);
          const content = await rawResponse.json();

          return content;
        } catch (err) {
          console.error("autocomplete error", err);
          resolve(null);
        }
      };

      req().then((response) => {
        console.log("autocomplete response", response);
        resolve(response);
      });
    });
  };
}
